import {
  ColDef,
  ValueFormatterParams,
  ICellRendererParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomizedStatus from "../../../Custom/CustomizedStatus";

type Props = {
  isPromotion?: boolean;
  isPreOrderQuota?: Boolean;
};

const useBranchColumnDefs = ({ isPromotion, isPreOrderQuota }: Props) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    setColumnDefs(
      [
        isPreOrderQuota && {
          valueFormatter: (params: ICellRendererParams) => {
            return (params?.node?.rowIndex || 0) + 1;
          },
          filter: false,
          width:65,
        },
        {
          field: "unique_id",
          headerName: `รหัส${t("branch.index")}`,
          filter: "agTextColumnFilter",
          sort: isPreOrderQuota ? "desc" : undefined,
        },
        {
          field: "name",
          headerName: isPreOrderQuota ? t("branch.index") : t("branch.name"),
          filter: "agTextColumnFilter",
        },
        {
          field: "zone",
          headerName: t("branch.zone"),
          filter: "agTextColumnFilter",
        },
        {
          field: "address",
          headerName: t("address.index"),
          filter: "agTextColumnFilter",
          valueGetter: (params: ValueFormatterParams) => {
            if (params.data?.address) {
              const {
                address_name,
                sub_district,
                district,
                province,
                postal_code,
              } = params.data.address;
              return `${address_name} ${sub_district} ${district} ${province} ${postal_code}`;
            }
            return "-";
          },
        },
        !isPromotion &&
          !isPreOrderQuota && {
            field: "phone",
            headerName: t("branch.phone"),
            filter: "agTextColumnFilter",
          },
        !isPromotion &&
          !isPreOrderQuota && {
            field: "fax",
            headerName: t("branch.fax_alt"),
            filter: "agTextColumnFilter",
          },
        {
          field: "status",
          headerName: isPreOrderQuota ? t("status.index") : t("branch.status"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: [1, 0],
            valueFormatter: ({ value }: { value: number }) =>
              value ? "ใช้งาน" : "หยุดใช้งาน",
          },
          cellRenderer: ({ value }: { value: number }) => {
            return <CustomizedStatus status={value ? "active" : "inactive"} />;
          },
          cellStyle: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      ].filter(Boolean) as ColDef[]
    );
  }, [t, isPromotion, isPreOrderQuota]);

  return columnDefs;
};

export default useBranchColumnDefs;
