import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { IPreOrderQuotaSetting } from "../../../types/Inventory/PreOrderQuota";
import { IUser } from "../../../types/Auth/user";

export const preOrderQuotaCreateFormatter = (
  data: IPreOrderQuotaSetting,
  user: IUser | null
) => {
  const {
    is_edit_page,
    branch_list,
    has_shared_quota,
    shared_quota,
    root_item_list,
    item_quota_list,
    is_allowed_in_all_branches,
    is_active,
    tab,
    ...otherData
  } = data;

  const created_by = {
    user_id: user?.id || 0,
    user_unique_id: user?.unique_id || "",
    email: user?.email || "",
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    img_url: user?.img_url || [],
  };

  const root_item_unique_id_list = root_item_list.map(
    (item) => item.item_unique_id
  );

  const branch_id_list = branch_list.map((branch) => branch.id);

  const formattedHasSharedQuota = formatStringBoolean(has_shared_quota);

  const formattedIsAllowedInAllBranches = formatStringBoolean(
    is_allowed_in_all_branches
  );

  const formattedItemList = item_quota_list.map((itemGroup) => {
    return itemGroup.map((item) => {
      const quota = formattedHasSharedQuota ? shared_quota ?? 0 : item.quota;
      return {
        ...item,
        quota,
        available_qty: undefined,
        unique_id: undefined,
        branch_unique_id: undefined,
        branch_name: undefined,
        is_shared_quota: undefined,
        group_index: undefined,
        in_group_index: undefined,
      };
    });
  });

  const formatted = {
    ...otherData,
    created_by,
    has_shared_quota: formattedHasSharedQuota,
    is_allowed_in_all_branches: formattedIsAllowedInAllBranches,
    shared_quota,
    branch_id_list,
    item_unique_id_list: root_item_unique_id_list,
    item_quota_list: formattedItemList,
    is_active: is_active ? 1 : 0,
  };

  return formatted;
};

export const preOrderQuotaUpdateFormatter = (
  data: IPreOrderQuotaSetting,
  user: IUser | null
) => {
  const {
    id,
    is_edit_page,
    last_updated_date,
    branch_list,
    has_shared_quota,
    shared_quota,
    root_item_list,
    item_quota_list,
    is_allowed_in_all_branches,
    unique_id,
    created_date,
    created_by,
    tab,
    applied_branch_id_list,
    locked_branch_unique_id_list,
    locked_root_item_unique_id_list,
    is_active,
    is_expired,
    ...otherData
  } = data;

  const last_updated_by = {
    user_id: user?.id || 0,
    user_unique_id: user?.unique_id || "",
    email: user?.email || "",
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    img_url: user?.img_url || [],
  };

  const root_item_unique_id_list = root_item_list.map(
    (item) => item.item_unique_id
  );

  const branch_id_list = branch_list.map((branch) => branch.id);

  const formattedHasSharedQuota = formatStringBoolean(has_shared_quota);

  const formattedIsAllowedInAllBranches = formatStringBoolean(
    is_allowed_in_all_branches
  );

  const formattedItemList = item_quota_list.map((itemGroup) => {
    return itemGroup.map(({ quota, ordered_qty, ...item }) => {
      const formattedQuota = formattedHasSharedQuota
        ? shared_quota ?? 0
        : quota;
      return {
        ...item,
        ordered_qty,
        quota: formattedQuota,
        available_qty: undefined,
        unique_id: undefined,
        branch_unique_id: undefined,
        branch_name: undefined,
        branch: undefined,
        is_shared_quota: undefined,
        group_index: undefined,
        in_group_index: undefined,
        last_updated_date: undefined,
        setting_id: undefined,
      };
    });
  });

  return {
    ...otherData,
    has_shared_quota: formattedHasSharedQuota,
    is_allowed_in_all_branches: formattedIsAllowedInAllBranches,
    last_updated_by,
    shared_quota,
    branch_id_list,
    item_unique_id_list: root_item_unique_id_list,
    item_quota_list: formattedItemList,
    is_active: is_active ? 1 : 0,
  };
};

export const preOrderQuotaQueryFormatter = (data: any) => {
  const {
    start_date,
    branch_list,
    end_date,
    applied_branch_id_list,
    ...otherData
  } = data;

  const root_item_list = data.item_quota_list?.map((itemGroup: any) => {
    const firstItem = itemGroup[0];
    return {
      unique_id: uuidv4(),
      item_id: firstItem.item_id,
      item_unique_id: firstItem.item_unique_id,
      item_sku_name: firstItem.item_unique_id,
      item_name: firstItem.item_name,
      unit: firstItem.uom_name,
      uom_id: firstItem.uom_id,
      uom_name: firstItem.uom_name,
      uom_group: firstItem.uom_group,
    };
  });

  const locked_root_item_unique_id_list = root_item_list.map(
    (item: any) => item.item_unique_id
  );

  const formattedItemQuotaList = data.item_quota_list?.map((itemGroup: any) => {
    return itemGroup.map((item: any) => {
      const matchingBranch = branch_list.find(
        (branch: any) => branch.id === item.branch_id
      );
      return {
        ...item,
        branch_unique_id: matchingBranch.unique_id,
        branch_name: matchingBranch.name,
      };
    });
  });

  const hasAvailableQuota = formattedItemQuotaList?.some((itemGroup: any) => {
    return itemGroup.some((item: any) => item.available_qty > 0);
  });

  const formattedBranchList = branch_list.map((branch: any, index: number) => {
    return {
      ...branch,
      index,
    };
  });

  const locked_branch_unique_id_list = branch_list
    .filter((branch: any) => applied_branch_id_list?.includes(branch.id))
    .map((branch: any) => branch.unique_id);

  const formatted = {
    ...otherData,
    branch_list: formattedBranchList,
    start_date: start_date ? dayjs(start_date) : null,
    end_date: end_date ? dayjs(end_date) : null,
    tab: "rootItem",
    root_item_list,
    locked_branch_unique_id_list,
    locked_root_item_unique_id_list,
    item_quota_list: formattedItemQuotaList,
    is_edit_page: true,
    is_expired: dayjs().isAfter(dayjs(end_date)) || !hasAvailableQuota,
  };
  return formatted;
};

const formatStringBoolean = (value: boolean | string) => {
  if (value === true || value === "true") {
    return true;
  } else {
    return false;
  }
};
