import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useFormContext,
  useForm,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import ModalUI from "../../../../UI/Modal/ModalUI";
import ControlledSelect from "../../../../Controller/ControlledSelect";
import ControlledNumberTextField from "../../../../Controller/ControlledNumberTextField";
import CustomizedButton from "../../../../Custom/CustomizedButton";
import { ISelectOption } from "../../../../../types/global";
import {
  IPreOrderQuotaSetting,
  IPreOrderQuotaAutofillModal,
} from "../../../../../types/Inventory/PreOrderQuota";
import {
  preOrderItemQuotaAutofillHeader as headers,
  textAlignToJustify,
} from "../../../../../hooks/Inventory/use-preorder-quota-list-header";

type Props = {
  open: boolean;
  onClose: () => void;
};

const defaultAutofillSchema = {
  apply_option: "all_equal",
  quota_list: [],
};

const QuotaAutofillModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const {
    control: rootControl,
    getValues: rootFormGetValues,
    setValue: rootFormSetValue,
  } = useFormContext<IPreOrderQuotaSetting>();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IPreOrderQuotaAutofillModal>({
    defaultValues: {
      ...defaultAutofillSchema,
    },
  });

  const watchRootItemList = useWatch({
    control: rootControl,
    name: "root_item_list",
  });

  const { fields } = useFieldArray({
    control,
    name: "quota_list",
  });

  useEffect(() => {
    reset({
      ...defaultAutofillSchema,
      quota_list:
        watchRootItemList?.map((item: any) => {
          return {
            item_unique_id: item?.item_unique_id,
            quota: 0,
          };
        }) ?? [],
    });
  }, [reset, watchRootItemList]);

  const applyOptions: ISelectOption[] = [
    {
      label: t("inventory.preorder_quota.sentence.all_equal"),
      value: "all_equal",
    },
    {
      label: t("inventory.preorder_quota.sentence.equally_divided"),
      value: "equally_divided",
    },
  ];

  const autofillQuotaHandler = (data: any) => {
    const { apply_option, quota_list } = data;
    const item_quota_list = rootFormGetValues("item_quota_list");

    let fraction = 0;

    if (apply_option === "equally_divided") {
      fraction = item_quota_list?.[0]?.length ?? 1;
    }

    const formattedItemQuotaList = item_quota_list.map((item) => {
      const matchingQuota = quota_list.find(
        (quota: any) => quota.item_unique_id === item[0]?.item_unique_id
      );
      return item.map((item_quota) => {
        return {
          ...item_quota,
          quota:
            apply_option === "all_equal"
              ? matchingQuota.quota ?? 0
              : Math.floor((matchingQuota.quota ?? 0) / (fraction || 1)),
        };
      });
    });

    rootFormSetValue("item_quota_list", formattedItemQuotaList);
    onClose();
  };

  return (
    <ModalUI
      title={t("inventory.preorder_quota.sentence.autofill_helper")}
      maxWidth="xs"
      open={open}
      handleClose={onClose}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          <CustomizedButton
            title={t("button.save")}
            variant="contained"
            onClick={handleSubmit(autofillQuotaHandler)}
          />
        </Box>
      }
    >
      <TableContainer>
        <Table sx={{ minWidth: "100%", overflow: "scroll", marginTop: 1 }}>
          <TableHead sx={{ bgcolor: "primary.light" }}>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={header.align}
                  key={`qt-autofill-header-${index}`}
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={header.width}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      width: header.width,
                      justifyContent: textAlignToJustify(header.align),
                    }}
                  >
                    <Typography fontSize={14} fontWeight={600}>
                      {header.thaiLabel}
                    </Typography>
                    {header.required && (
                      <Typography
                        fontSize={14}
                        color={"error.main"}
                        fontWeight={600}
                      >
                        *
                      </Typography>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => {
              return (
                <TableRow
                  key={field.item_unique_id}
                  sx={{
                    verticalAlign: "center",
                    "& .MuiTableCell-root": {
                      borderBottom: "none",
                      padding: "0.5rem 1rem",
                    },
                  }}
                >
                  <TableCell align={headers[0]?.align}>
                    <Box width={headers[0]?.width}>
                      <Typography>{field.item_unique_id}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell align={headers[1]?.align}>
                    <Box width={headers[1]?.width}>
                      <ControlledNumberTextField
                        fullWidth
                        control={control}
                        name={`quota_list.${index}.quota`}
                        error={Boolean(errors.quota_list?.[index]?.quota)}
                        helperText={errors.quota_list?.[
                          index
                        ]?.quota?.message?.toString()}
                        decimalScale={0}
                        textAlign="right"
                        disableNegative
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              );
              // return (
              //   <ControlledNumberTextField
              //     fullWidth
              //     control={control}
              //     name={`quota_list.${index}.quota`}
              //     error={Boolean(errors.quota_list?.[index]?.quota)}
              //     helperText={errors.quota_list?.[
              //       index
              //     ]?.quota?.message?.toString()}
              //     decimalScale={0}
              //     disableNegative
              //   />
              // );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography color="primary" fontWeight={600} mt={1}>
        {t("inventory.variant.option")}
      </Typography>
      <ControlledSelect
        control={control}
        name="apply_option"
        options={applyOptions}
        sx={{ mt: 1 }}
      />
    </ModalUI>
  );
};

export default QuotaAutofillModal;
