import { Alert, Box, Grid, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import { useDisable } from "../../../../hooks/use-disable";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { IPromotion } from "../../../../types/Marketing/Promotion";
// import CustomizedStatus from "../../../Custom/CustomizedStatus";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import { IMenuOption } from "../../../../types/global";
import { useConfirmation } from "../../../../hooks/use-confirmation";
import DeleteConfirmation from "../../../UI/Modal/DeleteConfirmation";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useCallback, useEffect, useMemo } from "react";
import {
  MarketingModelType,
  MarketingUniqueIdGenerateQuery,
  useMarketingUniqueIdGenerateQuery,
} from "../../../../generated/marketing";
import { useStateContext } from "../../../../contexts/auth-context";

type Props = {
  type:
    | "discount_product"
    | "discount_total"
    | "discount_fix_price"
    | "discount_order"
    | "free_gift";
  editClickHandler: () => void;
  deleteHandler: () => Promise<void>;
};

const PromotionHeader = ({ type, editClickHandler, deleteHandler }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<IPromotion>();
  const { id } = useParams();
  const [disabled] = useDisable();
  const {
    state: { permissions },
  } = useStateContext();

  // const status = watch("status");
  const name = watch("name");
  const isApplied = watch("is_applied");
  const uniqueId = watch("unique_id");

  const prevEndDate = watch("end_date_prev");

  const graphQLClient = createGraphQLClientWithMiddleware("marketing");

  const { refetch: refetchUniqueId } =
    useMarketingUniqueIdGenerateQuery<MarketingUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: MarketingModelType.PromotionSetting,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const selectModifyOptions = useMemo(() => {
    const options = [];
    if (permissions?.promotion?.update) {
      options.push({
        value: "แก้ไข",
        disabled: !disabled,
      });
    }
    if (permissions?.promotion?.delete) {
      options.push({
        value: "ลบ",
        disabled: false,
      });
    }
    return options;
  }, [permissions?.promotion?.update, permissions?.promotion?.delete, disabled]);

  const {
    confirmation: deleteConfirmation,
    openConfirmationHandler: openDeleteConfirmation,
    closeConfirmationHandler: closeDeleteConfirmation,
    submitConfirmationHandler: submitDeleteConfirmation,
  } = useConfirmation(deleteHandler);

  return (
    <Box maxWidth={1040}>
      <Grid container spacing={1.5} mt={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5" sx={{ color: "#333333" }}>
              {id
                ? `${t(`marketing.promotion.type.${type}`)} ${uniqueId}`
                : `${t("setting.index")}${t(
                    `marketing.promotion.type.${type}`
                  )}`}
            </Typography>
            {/* <CustomizedStatus status={status} /> */}
          </Box>
        </Grid>
        {(permissions?.promotion?.update || permissions?.promotion?.delete) && (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={1.5} justifyContent="flex-end">
              <Grid item xs={5.5} sm={5.5} md={4} lg={4} xl={4}>
                {
                  <CustomizedMenuOptions
                    fullWidth
                    size="medium"
                    label={"ตัวเลือก"}
                    options={selectModifyOptions}
                    onSelect={(e) => {
                      const value = e.target as HTMLElement;
                      switch (value.innerText) {
                        case "แก้ไข":
                          editClickHandler();
                          break;
                        case "ลบ":
                          openDeleteConfirmation();
                          break;
                        default:
                          break;
                      }
                    }}
                    disabled={!id}
                  />
                }
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1.5} alignItems="center">
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1.5} alignItems="center">
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("marketing.promotion.unique_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledTextField
                name="unique_id"
                control={control}
                placeholder={t("marketing.promotion.unique_id")}
                error={Boolean(errors?.unique_id)}
                helperText={
                  errors?.unique_id && errors?.unique_id.message?.toString()
                }
                disabled={!!id}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={1}>
              {!Boolean(id) && (
                <CustomizedTooltip
                  title={`เรียก${t("marketing.promotion.unique_id")}ใหม่`}
                  enterNextDelay={200}
                >
                  <IconButton
                    onClick={generateUniqueId}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1.5} justifyContent="flex-end">
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="start_date"
                control={control}
                label={t("date.promotion.start_date")}
                disabled={disabled || isApplied}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="end_date"
                control={control}
                label={t("date.promotion.end_date")}
                disabled={disabled}
                viewMode={disabled}
                minDate={prevEndDate}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DeleteConfirmation
        title={`ลบ${t("marketing.promotion.index")}`}
        message={
          <Box>
            <Typography>{`ยืนยันการลบ${t(
              "marketing.promotion.index"
            )}`}</Typography>
            <Typography fontWeight="bold">{name}</Typography>
            <Alert severity="warning">
              {t("marketing.promotion.confirmation.delete")}
            </Alert>
          </Box>
        }
        open={deleteConfirmation}
        handleClose={closeDeleteConfirmation}
        action={submitDeleteConfirmation}
        titleColor={"#333333"}
        noDivider
      />
    </Box>
  );
};

export default PromotionHeader;
