import { IPreOrderQuotaSetting } from "../../../../types/Inventory/PreOrderQuota";
import dayjs from "dayjs";
import * as Yup from "yup";

export const preOrderQuotaSettingSchema: IPreOrderQuotaSetting = {
  unique_id: "",
  name: "",
  is_active: 1,
  is_allowed_in_all_branches: true,
  start_date: dayjs().startOf("day"),
  end_date: dayjs().endOf("day"),
  branch_id_list: [],
  branch_list: [],
  root_item_list: [],
  item_unique_id_list: [],
  item_quota_list: [],
  has_shared_quota: true,
  tab: "rootItem",
};

export const itemQuotaListvalidation = Yup.object().shape({
  available_qty: Yup.number().min(0, "กรุณาระบุ"),
  quota: Yup.number().min(0, "กรุณาระบุ"),
});

export const preOrderQuotaSettingValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุ"),
  start_date: Yup.date()
    .required("กรุณาระบุ")
    .max(Yup.ref("end_date"), "กรุณาระบุข้อมูลให้ถูกต้อง"),
  end_date: Yup.date()
    .required("กรุณาระบุ")
    .test(
      "is-before-end_date_prev",
      "ไม่สามารถแก้ไขให้วันน้อยกว่าเดิมได้",
      (end_date, context) => {
        const { end_date_prev } = context.parent;
        if (end_date_prev) {
          const startOfEndDate = dayjs(end_date).startOf("day");
          const startOfEndDatePrev = dayjs(end_date_prev).startOf("day");
          return (
            startOfEndDate.isAfter(startOfEndDatePrev) ||
            startOfEndDate.isSame(startOfEndDatePrev)
          );
        }
        return true;
      }
    ),
  name: Yup.string().required("กรุณาระบุ"),
  branch_list: Yup.array().min(1, "กรุณาเลือก"),
  root_item_list: Yup.array().min(1, "กรุณาเลือก"),
  item_quota_list: Yup.array().of(
    Yup.array().when("has_shared_quota", (has_shared_quota: any) => {
      return has_shared_quota === true || has_shared_quota === "true"
        ? Yup.array().nullable()
        : Yup.array().of(itemQuotaListvalidation);
    })
  ),
  shared_quota: Yup.number().when(
    ["has_shared_quota", "tab", "is_edit_page"],
    (value: any) =>
      (value[0] === true || value[0] === "true") &&
      (value[1] === "quota" || value[2])
        ? Yup.number()
            .required("กรุณาระบุ")
            .typeError("กรุณาระบุ")
            .test(
              "is-gte-ordered-qty",
              "กรุณาระบุโควตาให้มากกว่าจํานวนที่สั่ง",
              (value: any, context: any) => {
                const { total_ordered_qty } = context.parent;
                return value >= (total_ordered_qty ?? 0);
              }
            )
        : Yup.number().nullable()
  ),
});
