import * as Yup from "yup";
import dayjs from "dayjs";
import {
  AdditionalDiscountType,
  PriceVatType,
} from "../../../../generated/sales";
import { ICustomerDeposit } from "../../../../types/Sales/customerDeposit";
import {
  paymentChannelValidation,
  salesPaymentChannelSchema,
} from "../CashSales/schema";
import { billingAddressValidation } from "../CustomerInfo/schema";

export const customerDepositSchema: ICustomerDeposit = {
  unique_id: "",
  created_by: null,
  created_date: dayjs(),
  issue_date: dayjs(),
  due_date: dayjs(),
  reference_document_list: [],
  additional_discount: 0,
  additional_discount_type: AdditionalDiscountType.Baht,
  price_vat_type: PriceVatType.IncludeVat,
  currency: "THB",
  customer_details: {
    unique_id_name: "",
    name: "",
    phone: "",
    email: "",
    fax: "",
    identity_no: "",
    billing_address: {
      address_name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  },
  remark: "",
  deposit_list: [
    {
      name: "",
      pre_vat_amount: 0,
      reference_document_id: "",
      remark: "",
      vat_percentage: "ไม่มี",
      withholding_tax_type: "ยังไม่ระบุ",
      withholding_tax_value: 0,
    },
  ],
  net_amount: 0,
  pre_vat_amount: 0,
  related_user_list: [],
  post_discount_amount: 0,
  total_amount: 0,
  vat_0_percent_amount: 0,
  vat_7_percent_amount: 0,
  vat_amount: 0,
  vat_exempted_amount: 0,
  customer_unique_id: "",
  withholding_tax_amount: 0,
  reference_no: "",
  tag_list: [],
  branch_id: undefined,
  need_tax_invoice: true,
  tax_invoice_no: "",
  external_reference_no: "",
  payment_list: [salesPaymentChannelSchema],
  deduction_document_list: [],
  remaining_deposit_amount: 0,
  contact_person_list: [],
};

const depositListValidation = Yup.object().shape({
  name: Yup.string().required("กรุณาระบุรายละเอียดรายการ"),
  pre_vat_amount: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวนเงิน")
    .required("กรุณาระบุจำนวนเงิน"),
});

export const customerDepositValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุ"),
  issue_date: Yup.date().required("กรุณาระบุ"),
  due_date: Yup.date().required("กรุณาระบุ"),
  need_tax_invoice: Yup.boolean(),
  customer_unique_id: Yup.string().required("กรุณาระบุ"),
  deposit_list: Yup.array()
    .of(depositListValidation)
    .min(1, "กรุณาเลือกอย่างน้อย 1 รายการ"),
  payment_list: Yup.array()
    .of(paymentChannelValidation)
    .test("payment_date_validation", "", (value, { parent, createError }) => {
      const { issue_date } = parent;
      let errors: any = [];

      if (value && value.length > 0) {
        value.some((payment, index) => {
          if (
            dayjs(payment.payment_date)
              .endOf("day")
              .isBefore(dayjs(issue_date).endOf("day"))
          ) {
            errors.push(
              createError({
                path: `payment_list.${index}.payment_date`,
                message: "วันรับชำระต้องมากกว่าหรือเท่ากับวันที่ออกเอกสาร",
              })
            );
          }
          return false;
        });
      }

      if (errors.length > 0) {
        throw new Yup.ValidationError(errors);
      }
      return true;
    }),
  customer_details: Yup.object()
    .shape({
      billing_address: billingAddressValidation,
    })
    .test(
      "required",
      "กรุณาระบุที่อยู่จดทะเบียน",
      function (value, { parent }) {
        const { billing_address } = value;
        if (parent.need_tax_invoice) {
          if (billing_address && billing_address.address_name) {
            return billing_address.address_name.trim().length > 0;
          }
          return false;
        }
        return true;
      }
    ),
});
