import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { Box, CircularProgress } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { useNavigate, useParams } from "react-router-dom";
import { useDisable } from "../../../hooks/use-disable";
import { useSnackbar } from "notistack";
import { useStateContext } from "../../../contexts/auth-context";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import {
  usePreOrderQuotaSettingFindUniqueQuery,
  PreOrderQuotaSettingFindUniqueQuery,
  usePreOrderQuotaSettingCreateMutation,
  PreOrderQuotaSettingCreateMutation,
  usePreOrderQuotaSettingUpdateMutation,
  PreOrderQuotaSettingUpdateMutation,
  usePreOrderQuotaSettingDeleteMutation,
  PreOrderQuotaSettingDeleteMutation,
  PreOrderQuotaSettingCreateInput,
  PreOrderQuotaSettingUpdateInput,
} from "../../../generated/inventory";
import { IPreOrderQuotaSetting } from "../../../types/Inventory/PreOrderQuota";
import {
  preOrderQuotaSettingSchema,
  preOrderQuotaSettingValidation,
} from "../../../components/Form/Inventory/PreOrderQuota/schema";
import {
  preOrderQuotaCreateFormatter,
  preOrderQuotaUpdateFormatter,
  preOrderQuotaQueryFormatter,
} from "../../../utils/Formatter/Inventory/PreOrderQuota";
import PreOrderQuotaSettingHeader from "../../../components/Form/Inventory/PreOrderQuota/Header";
import PreOrderQuotaSettingInfo from "../../../components/Form/Inventory/PreOrderQuota/Info";
import PreOrderQuotaSettingBranch from "../../../components/Form/Inventory/PreOrderQuota/Branch";
import PreOrderQuotaSettingRootItem from "../../../components/Form/Inventory/PreOrderQuota/RootItem";
import PreOrderQuotaSettingItemQuota from "../../../components/Form/Inventory/PreOrderQuota/ItemQuota";
import PreOrderQuotaSettingItemNavigateButtons from "../../../components/Form/Inventory/PreOrderQuota/NavigateButtons";

const PreOrderQuotaSettingContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { id } = useParams();
  const [, setDisabled] = useDisable();
  const navigate = useNavigate();

  const {
    state: { authUser },
  } = useStateContext();

  const methods = useForm<IPreOrderQuotaSetting>({
    defaultValues: { ...preOrderQuotaSettingSchema },
    resolver: yupResolver<any>(preOrderQuotaSettingValidation),
  });

  const { control, reset } = methods;

  const currentTab = useWatch({
    control,
    name: "tab",
  });

  const graphQLClient: GraphQLClient = createGraphQLClientWithMiddleware("wms");

  const { data, isLoading, isSuccess, refetch, isError } =
    usePreOrderQuotaSettingFindUniqueQuery<PreOrderQuotaSettingFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: { id: id ? parseInt(id) : 0 },
      },
      {
        enabled: Boolean(id),
      }
    );

  const { mutateAsync: create, isLoading: isCreating } =
    usePreOrderQuotaSettingCreateMutation<PreOrderQuotaSettingCreateMutation>(
      graphQLClient
    );

  const { mutateAsync: update, isLoading: isUpdating } =
    usePreOrderQuotaSettingUpdateMutation<PreOrderQuotaSettingUpdateMutation>(
      graphQLClient
    );

  const { mutateAsync: deleteSetting, isLoading: isDeleting } =
    usePreOrderQuotaSettingDeleteMutation<PreOrderQuotaSettingDeleteMutation>(
      graphQLClient
    );

  useEffect(() => {
    if (id && isSuccess) {
      const { PreOrderQuotaSettingFindUnique } = data;
      const formatData = preOrderQuotaQueryFormatter(
        PreOrderQuotaSettingFindUnique
      );
      reset(formatData);
    }
  }, [data, isSuccess, reset, id]);

  useEffect(() => {
    if (id && isError) {
      enqueueSnackbar("ไม่พบข้อมูลในระบบ", {
        variant: "error",
      });
      navigate("/inventory/preorder-quota");
    }
  }, [isError, id, navigate, enqueueSnackbar]);

  useEffect(() => {
    if (id) {
      setDisabled(true);
    }
    return () => {
      setDisabled(false);
    };
  }, [id, setDisabled]);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.preorder_quota.index"),
      to: "/inventory/preorder-quota",
    },
    {
      name: id
        ? data?.PreOrderQuotaSettingFindUnique?.unique_id || "-"
        : t("button.create") + t("inventory.preorder_quota.index"),
    },
  ];

  const cancelEditHandler = () => {
    if (!id) {
      reset({ ...preOrderQuotaSettingSchema });
      navigate("/inventory/preorder-quota");
    }
    setDisabled(true);
    reset(preOrderQuotaQueryFormatter(data?.PreOrderQuotaSettingFindUnique));
  };

  const createHandler = async (data: IPreOrderQuotaSetting) => {
    try {
      const formatted = preOrderQuotaCreateFormatter(data, authUser);

      const { PreOrderQuotaSettingCreate } = await create({
        createInput: formatted as PreOrderQuotaSettingCreateInput,
      });

      navigate(`/inventory/preorder-quota/${PreOrderQuotaSettingCreate.id}`);
      await refetch();
      enqueueSnackbar(
        `${t("button.create")}${t("inventory.preorder_quota.quota")}สำเร็จ`,
        {
          variant: "success",
        }
      );
    } catch (err) {
      const errorMessage = (err as any)?.message || "";
      let formattedErrorMessage = `${t("button.create")}${t(
        "inventory.preorder_quota.quota"
      )}ไม่สำเร็จ`;

      if (errorMessage?.includes("Duplicate unique id")) {
        formattedErrorMessage = "รหัสโควตาซ้ำกันภายในระบบ";
      } else if (errorMessage?.includes("Duplicate name")) {
        formattedErrorMessage = "ชื่อโควตาซ้ำกันภายในระบบ";
      } else if (errorMessage?.includes("Overlapped item")) {
        formattedErrorMessage = "สินค้าซ้ำในช่วงเวลาเดียวกัน";
      }

      enqueueSnackbar(formattedErrorMessage, {
        variant: "error",
      });
    }
  };

  const updateHandler = async (data: IPreOrderQuotaSetting) => {
    try {
      const formatted = preOrderQuotaUpdateFormatter(data, authUser);

      await update({
        updateInput: formatted as PreOrderQuotaSettingUpdateInput,
        uniqueInput: {
          id: parseInt(id ?? "-1"),
        },
      });
      await refetch();
      setDisabled(true);
      enqueueSnackbar(
        `${t("button.edit")}${t("inventory.preorder_quota.quota")}สำเร็จ`,
        {
          variant: "success",
        }
      );
    } catch (err) {
      const errorMessage = (err as any)?.message || "";
      let formattedErrorMessage = `${t("button.edit")}${t(
        "inventory.preorder_quota.quota"
      )}ไม่สำเร็จ`;

      if (errorMessage?.includes("Duplicate unique id")) {
        formattedErrorMessage = "รหัสโควตาซ้ำกันภายในระบบ";
      } else if (
        errorMessage?.includes("Duplicate name") ||
        errorMessage?.includes(
          "Unique constraint failed on the fields: (`name`)"
        )
      ) {
        formattedErrorMessage = "ชื่อโควตาซ้ำกันภายในระบบ";
      } else if (errorMessage?.includes("Overlapped item")) {
        formattedErrorMessage = "สินค้าซ้ำในช่วงเวลาเดียวกัน";
      }

      enqueueSnackbar(formattedErrorMessage, {
        variant: "error",
      });
    }
  };

  const deleteHandler = async () => {
    try {
      await deleteSetting({
        uniqueInput: {
          id: parseInt(id ?? "-1"),
        },
      });
      navigate("/inventory/preorder-quota");
      enqueueSnackbar(`ลบสำเร็จ`, {
        variant: "success",
      });
    } catch (err: any) {
      if (err.message?.includes("Pre-order Quota Setting is applied")) {
        enqueueSnackbar(`ลบไม่สำเร็จ เนื่องจากมีการอ้างอิงเอกสารอยู่`, {
          variant: "error",
        });
        return;
      }
      enqueueSnackbar(`ลบไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const editClickHandler = () => {
    setDisabled(false);
  };

  const isMutating = isCreating || isUpdating || isDeleting;

  if (id && (isLoading || isMutating)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <FormProvider {...methods}>
      <Box display={"flex"} justifyContent={"space-between"} maxWidth={1040}>
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      </Box>
      <PreOrderQuotaSettingHeader
        editClickHandler={editClickHandler}
        deleteHandler={deleteHandler}
      />
      {currentTab === "rootItem" && <PreOrderQuotaSettingInfo />}
      {currentTab === "rootItem" && <PreOrderQuotaSettingBranch />}
      {currentTab === "rootItem" && <PreOrderQuotaSettingRootItem />}
      {(id || currentTab === "quota") && <PreOrderQuotaSettingItemQuota />}
      <PreOrderQuotaSettingItemNavigateButtons
        cancelEditHandler={cancelEditHandler}
        createHandler={createHandler}
        updateHandler={updateHandler}
      />
    </FormProvider>
  );
};

export default PreOrderQuotaSettingContainer;
