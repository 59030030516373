import {
  ColDef,
  ValueFormatterParams,
  ICellRendererParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../utils/Date";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";

import CustomizedStatus from "../../../Custom/CustomizedStatus";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";

export const usePreOrderQuotaSettingColumnDefs = () => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    setColumnDefs([
      {
        field: "unique_id",
        headerName: `${t("inventory.preorder_quota.unique_id_short")}`,
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "name",
        headerName: `${t("inventory.preorder_quota.name")}`,
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "start_date",
        headerName: `${t("inventory.preorder_quota.start_date")}`,
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        flex: 1,
        minWidth: 200,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "end_date",
        headerName: `${t("inventory.preorder_quota.end_date")}`,
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        flex: 1,
        minWidth: 200,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "created_date",
        headerName: `${t("inventory.preorder_quota.created_date")}`,
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        sort: "desc",
        flex: 1,
        minWidth: 200,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "last_updated_date",
        headerName: `${t("inventory.preorder_quota.last_updated_date")}`,
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        flex: 1,
        minWidth: 200,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "created_by_object",
        headerName: t("inventory.preorder_quota.created_by"),
        filter: "agTextColumnFilter",
        cellRenderer: (params: ICellRendererParams<any, any>) => {
          if (params.value) {
            return <CustomizedAvatar avatars={[params.value]} />;
          }
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "is_active",
        headerName: `${t("status.index")}`,
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["active", "inactive"],
          valueFormatter: (params: ValueFormatterParams) =>
            t(`status.${params.value}`),
        },
        cellRenderer: ({ value }: { value: number }) => {
          return <CustomizedStatus status={value ? "active" : "inactive_alt"} />;
        },
        cellStyle: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        flex: 1,
        minWidth: 200,
        headerClass: "ag-center-header",
      },
    ]);
  }, [t]);

  return columnDefs;
};
