import { useFormContext, useFieldArray, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { Fragment } from "react";

import ControlledNumberTextField from "../../../../Controller/ControlledNumberTextField";
import { useDisable } from "../../../../../hooks/use-disable";
import CustomizedButton from "../../../../Custom/CustomizedButton";
import {
  usePreOrderItemQuotaListHeader,
  textAlignToJustify,
} from "../../../../../hooks/Inventory/use-preorder-quota-list-header";
import { IPreOrderQuotaSetting } from "../../../../../types/Inventory/PreOrderQuota";
import { useModal } from "../../../../../hooks/use-modal";
import { formatInt } from "../../../../../utils/Global";
import QuotaAutofillModal from "./QuotaAutofillModal";
import { usePreOrderQuotaSetting } from "../../../../../hooks/Inventory/use-preorder-quota-setting";

const ItemQuotaList = () => {
  const { t } = useTranslation();
  const [disabled] = useDisable();
  const { id } = useParams();
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<IPreOrderQuotaSetting>();
  const { recalculateSharedQuota } = usePreOrderQuotaSetting();

  const { fields } = useFieldArray({
    control,
    name: "item_quota_list",
    keyName: "unique_id",
  });

  const watchFieldArray = useWatch({
    control: control,
    name: "item_quota_list",
  });

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const hasSharedQuota = useWatch({ control, name: "has_shared_quota" });

  const headers = usePreOrderItemQuotaListHeader(
    disabled,
    hasSharedQuota,
    Boolean(id)
  );

  const {
    modal: quotaAutofillModal,
    openModalHandler: openQuotaAutofillModal,
    closeModalHandler: closeQuotaAutofillModal,
  } = useModal();

  const quotaOnBlurHandler = (
    value: any,
    groupIndex: number,
    inGroupIndex: number
  ) => {
    const currentItemOrderedQty =
      getValues(`item_quota_list.${groupIndex}.${inGroupIndex}.ordered_qty`) ??
      0;
    setValue(
      `item_quota_list.${groupIndex}.${inGroupIndex}.available_qty`,
      parseInt(value) - currentItemOrderedQty
    );
    recalculateSharedQuota();
  };

  return (
    <>
      {fields?.length > 0 && (
        <Box sx={{ display: "flex", alignItems: "center", mt: 2, gap: 1 }}>
          <Typography fontWeight={600}>
            {t("inventory.preorder_quota.sentence.quota_by_branch")}
          </Typography>
          {(!hasSharedQuota || hasSharedQuota === "false") && !disabled && (
            <CustomizedButton
              title={t("inventory.preorder_quota.sentence.autofill_helper")}
              variant="text"
              startIcon={<EditIcon sx={{ mr: -0.5 }} />}
              onClick={openQuotaAutofillModal}
            />
          )}
        </Box>
      )}
      {controlledFields.map((itemGroup, groupIndex) => {
        return (
          <Fragment key={itemGroup?.unique_id}>
            <Typography fontWeight={600} mt={2} color={"primary.main"}>
              {`${itemGroup?.[0]?.item_unique_id} - ${itemGroup?.[0]?.item_name}`}
            </Typography>
            <TableContainer
              sx={{
                maxHeight: 385,
                mt: 1,
                borderBottom: "0.5px solid #e0e0e0",
              }}
            >
              <Table sx={{ overflow: "scroll" }} stickyHeader>
                <TableHead>
                  <TableRow>
                    {headers.map((header, index) => (
                      <TableCell
                        align={header.align}
                        key={index}
                        sx={{
                          px: 1,
                          py: 1,
                          bgcolor: "primary.light",
                        }}
                        width={header.width}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            width: header.width,
                            justifyContent: textAlignToJustify(header.align),
                          }}
                        >
                          <Typography fontSize={14} fontWeight={600}>
                            {header.thaiLabel}
                          </Typography>
                          {header.required && (
                            <Typography
                              fontSize={14}
                              color={"error.main"}
                              fontWeight={600}
                            >
                              *
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(itemGroup)
                    .filter((key: any) => !isNaN(key))
                    .map((key) => {
                      const itemIndex = parseInt(key);
                      const currentItem = itemGroup?.[itemIndex];
                      return (
                        <TableRow
                          key={currentItem.unique_id}
                          sx={{ verticalAlign: "top" }}
                        >
                          <TableCell align={headers[0]?.align}>
                            <Box width={headers[0]?.width} py={1}>
                              <Typography>{itemIndex + 1}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell align={headers[1]?.align}>
                            <Box width={headers[1]?.width} py={1}>
                              <Typography>
                                {currentItem?.branch_unique_id}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell align={headers[2]?.align}>
                            <Box width={headers[2]?.width} py={1}>
                              <Typography>
                                {currentItem?.branch_name}
                              </Typography>
                            </Box>
                          </TableCell>

                          {(!hasSharedQuota || hasSharedQuota === "false") && (
                            <TableCell align={headers[4]?.align}>
                              <Box
                                width={headers[4]?.width}
                                py={disabled ? 1 : undefined}
                              >
                                <ControlledNumberTextField
                                  fullWidth
                                  control={control}
                                  name={`item_quota_list.${groupIndex}.${itemIndex}.quota`}
                                  error={Boolean(
                                    errors.item_quota_list?.[groupIndex]?.[
                                      itemIndex
                                    ]?.quota
                                  )}
                                  helperText={errors.item_quota_list?.[
                                    groupIndex
                                  ]?.[itemIndex]?.message?.toString()}
                                  viewMode={disabled}
                                  decimalScale={0}
                                  disableNegative
                                  onBlur={(event) => {
                                    quotaOnBlurHandler(
                                      event.target.value ?? "0",
                                      groupIndex,
                                      itemIndex
                                    );
                                  }}
                                />
                              </Box>
                            </TableCell>
                          )}
                          {id && hasSharedQuota && (
                            <TableCell align={headers[3]?.align}>
                              <Box width={headers[3]?.width} py={1}>
                                <Typography>
                                  {`${formatInt(currentItem?.ordered_qty)}`}
                                </Typography>
                              </Box>
                            </TableCell>
                          )}
                          {id && !hasSharedQuota && (
                            <TableCell align={headers[4]?.align}>
                              <Box width={headers[4]?.width} py={1}>
                                <Typography>
                                  {`${formatInt(currentItem?.ordered_qty)}`}
                                </Typography>
                              </Box>
                            </TableCell>
                          )}
                          {id && !hasSharedQuota && (
                            <TableCell align={headers[4]?.align}>
                              <Box width={headers[4]?.width} py={1}>
                                <Typography>
                                  {`${formatInt(
                                    (currentItem?.quota ?? 0) -
                                      (currentItem?.ordered_qty ?? 0)
                                  )}`}
                                </Typography>
                              </Box>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Fragment>
        );
      })}
      {quotaAutofillModal && (
        <QuotaAutofillModal
          open={quotaAutofillModal}
          onClose={closeQuotaAutofillModal}
        />
      )}
    </>
  );
};

export default ItemQuotaList;
