import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { useForm, FormProvider } from "react-hook-form";
import {
  contactSchema,
  contactValidation,
} from "../../../components/Form/Contact/schema";
import { IContact, IContactAttribute } from "../../../types/Contact/contact";
import ContactChannel from "../../../components/Form/Contact/ContactChannel";
import ContactTag from "../../../components/Form/Contact/Tag";
import ContactRemark from "../../../components/Form/Contact/Remark";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { useCallback, useEffect, useMemo, useState } from "react";
import ContactAddress from "../../../components/Form/Contact/Address";
import ContactPersonList from "../../../components/Form/Contact/ContactPersonList";
import ContactFinance from "../../../components/Form/Contact/Finance";
import ContactAttribute from "../../../components/Form/Contact/Attribute";
import SalesPersonList from "../../../components/Form/Contact/SalesPersonList";
import ContactAttachment from "../../../components/Form/Contact/Attachment";
import ContactMember from "../../../components/Form/Contact/Member";
import ContactInfo from "../../../components/Form/Contact/Info";
import ContactDetail from "../../../components/Form/Contact/Detail";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { Stack, Box, CircularProgress, IconButton } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useDisable } from "../../../hooks/use-disable";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import {
  CrmModelType,
  CrmUniqueIdGenerateQuery,
  CustomerFindUniqueQuery,
  useCrmUniqueIdGenerateQuery,
  useCustomerCreateMutation,
  useCustomerDeleteMutation,
  useCustomerFindUniqueQuery,
  useCustomerUpdateMutation,
} from "../../../generated/contact";
import { useSnackbar } from "notistack";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  customerCreateFormatter,
  customerQueryFotmatter,
  customerUpdateFormatter,
} from "../../../utils/Formatter/Contact";
import { useContactOption } from "../../../hooks/use-contact-option";
import { useModal } from "../../../hooks/use-modal";
import ConfirmationModal from "../../../components/UI/Modal/ConfirmationModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessageFormatter } from "../../../utils/Global";
import { ActivityLogSlideInPanel } from "../../../components/UI/SlideInPanel/ActivityLogSlideInPanel";
import {
  ActivityType,
  ActivityLogDocumentType,
} from "../../../generated/general";
import { useActivityLog } from "../../../hooks/use-activity-log";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import { useStateContext } from "../../../contexts/auth-context";

const CustomerContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { pathname } = useLocation();
  const [disabled, setDisabled] = useDisable();
  const navigate = useNavigate();
  const { createActivityLog } = useActivityLog();

  const { enqueueSnackbar } = useSnackbar();

  const [currentTab, setCurrentTab] = useState(`${pathname}?tab=address`);
  const [showActivityLog, setShowActivityLog] = useState(false);

  const graphqlClientCrm = createGraphQLClientWithMiddleware("crm");

  const {
    state: { permissions },
  } = useStateContext();

  const selectModifyOptions = useMemo(() => {
    const options = [];
    if (permissions?.customer?.update) {
      options.push({
        value: "แก้ไข",
        disabled: false,
      });
    }
    if (permissions?.customer?.delete) {
      options.push({
        value: "ลบ",
        disabled: false,
      });
    }
    return options;
  }, [permissions?.customer?.update, permissions?.customer?.delete]);

  const tabs: ITab[] = [
    {
      label: t("contact.address.index"),
      path: `${pathname}?tab=address`,
    },
    {
      label: t("contact.contact_person.index"),
      path: `${pathname}?tab=contact_person`,
    },
    {
      label: t("contact.financial.index"),
      path: `${pathname}?tab=financial`,
    },
    {
      label: "ข้อมูล" + t("contact.attribute.index"),
      path: `${pathname}?tab=attribute`,
    },
    {
      label: t("contact.attachment.index"),
      path: `${pathname}?tab=attachment`,
    },
    {
      label: t("contact.sales_person.index"),
      path: `${pathname}?tab=sales_person`,
    },
    {
      label: t("contact.member.index"),
      path: `${pathname}?tab=member`,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "address":
        setCurrentTab(pathname + "?tab=address");
        break;
      case "contact_person":
        setCurrentTab(pathname + "?tab=contact_person");
        break;
      case "financial":
        setCurrentTab(pathname + "?tab=financial");
        break;
      case "attribute":
        setCurrentTab(pathname + "?tab=attribute");
        break;
      case "attachment":
        setCurrentTab(pathname + "?tab=attachment");
        break;
      case "sales_person":
        setCurrentTab(pathname + "?tab=sales_person");
        break;
      case "member":
        setCurrentTab(pathname + "?tab=member");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const methods = useForm<IContact>({
    defaultValues: contactSchema,
    resolver: yupResolver<any>(contactValidation),
  });

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "address":
        return (
          <ContactAddress
            addressList={data?.CustomerFindUnique?.address_list}
          />
        );
      case "contact_person":
        return <ContactPersonList />;
      case "financial":
        return <ContactFinance />;
      case "attribute":
        return <ContactAttribute type="customer" />;
      case "attachment":
        return <ContactAttachment />;
      case "sales_person":
        return <SalesPersonList />;
      case "member":
        return <ContactMember />;
      default:
        return null;
    }
  };

  const { handleSubmit, reset, setValue } = methods;

  const { isLoading: isOptionsLoading, attributes } =
    useContactOption("customer");

  const {
    data,
    isLoading,
    isSuccess,
    refetch: refetchCustomer,
  } = useCustomerFindUniqueQuery<CustomerFindUniqueQuery>(
    graphqlClientCrm,
    {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    },
    {
      enabled: !!id,
    }
  );

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("contact.index"),
      to: "/contact",
    },
    {
      name: t("contact.contact_type.customer"),
      to: "/contact/customer",
    },
    {
      name:
        id && Boolean(id)
          ? data && data.CustomerFindUnique?.unique_id
            ? data.CustomerFindUnique.unique_id
            : ""
          : t(`${t("button.add")}${t("contact.contact_type.customer")}`),
    },
  ];

  const { refetch: refetchUniqueId } =
    useCrmUniqueIdGenerateQuery<CrmUniqueIdGenerateQuery>(
      graphqlClientCrm,
      {
        modelType: CrmModelType.Customer,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(
    async (button: boolean) => {
      const { data } = await refetchUniqueId();
      if (!id || button) setValue("unique_id", data?.UniqueIdGenerate ?? "");
    },
    [id, refetchUniqueId, setValue]
  );

  useEffect(() => {
    if (id) {
      setDisabled(true);
    }
    return () => setDisabled(false);
  }, [id, setDisabled]);

  useEffect(() => {
    if (isSuccess) {
      const { CustomerFindUnique } = data;
      const customerData = customerQueryFotmatter({
        ...CustomerFindUnique,
      } as IContact);

      if (attributes && attributes.length > 0) {
        const currentAttributes = customerData.attribute_list;
        let formatAttributes: IContactAttribute[] = attributes.map(
          (attribute) => ({
            name: attribute.name || "",
            short_name: attribute.short_name || "",
            value:
              currentAttributes.find((curr) => curr.name === attribute.name)
                ?.value || "",
          })
        );
        reset({ ...customerData, attribute_list: formatAttributes });
      } else {
        reset(customerData);
      }
    }
  }, [attributes, data, isSuccess, reset]);

  useEffect(() => {
    generateUniqueId(false);
  }, [generateUniqueId]);

  const { mutateAsync: create, isLoading: isCreating } =
    useCustomerCreateMutation<Error>(graphqlClientCrm);

  const { mutateAsync: update, isLoading: isUpdating } =
    useCustomerUpdateMutation<Error>(graphqlClientCrm);

  const { mutateAsync: deleteCustomer, isLoading: isDeleting } =
    useCustomerDeleteMutation<Error>(graphqlClientCrm);

  const editCancelHandler = () => {
    setDisabled(true);
    reset();
  };

  const customerCreateHandler = async (data: IContact) => {
    try {
      const formatData = await customerCreateFormatter(data, id);
      const { CustomerCreate } = await create({
        data: formatData,
      });
      await createActivityLog({
        activity_type: ActivityType.Create,
        document_type: ActivityLogDocumentType.Customer,
        reference_id: CustomerCreate?.id,
        activity_detail: {},
      });
      enqueueSnackbar("สร้างลูกค้าสำเร็จ", {
        variant: "success",
      });
      navigate(`/contact/customer/${CustomerCreate?.id}?tab=address`);
    } catch (err) {
      const formatError = errorMessageFormatter(err);
      enqueueSnackbar(formatError || "สร้างลูกค้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const customerUpdateHandler = async (data: IContact) => {
    try {
      const { id } = data;
      const customerSnapshot = await refetchCustomer();
      const formatData = await customerUpdateFormatter(data);
      const { CustomerUpdate } = await update({
        uniqueInput: {
          id: id,
        },
        data: formatData,
      });
      await createActivityLog({
        activity_type: ActivityType.Edit,
        document_type: ActivityLogDocumentType.Customer,
        reference_id: CustomerUpdate?.id,
        activity_detail: {},
      });
      const previousStatus = customerSnapshot?.data?.CustomerFindUnique?.status;
      if (previousStatus !== CustomerUpdate?.status) {
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.Customer,
          reference_id: CustomerUpdate?.id,
          activity_detail: {
            prev_status: previousStatus === 1 ? "active" : "inactive",
            curr_status: CustomerUpdate?.status === 1 ? "active" : "inactive",
          },
        });
      }
      const updatedData = customerQueryFotmatter({
        ...CustomerUpdate,
      } as IContact);
      reset(updatedData);
      setDisabled(true);
      enqueueSnackbar("แก้ไขลูกค้าสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      const formatError = errorMessageFormatter(err);
      enqueueSnackbar(formatError || "แก้ไขลูกค้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const customerDeleteHandler = async () => {
    try {
      const { CustomerDelete } = await deleteCustomer({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      });
      await createActivityLog({
        activity_type: ActivityType.Delete,
        document_type: ActivityLogDocumentType.Customer,
        reference_id: CustomerDelete?.id,
        activity_detail: {},
      });
      enqueueSnackbar(`ลบ${t("contact.contact_type.customer")}สำเร็จ`, {
        variant: "success",
      });
      navigate("/contact/customer");
    } catch (err) {
      enqueueSnackbar(`ลบ${t("contact.contact_type.customer")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const {
    modal: confirmation,
    openModalHandler: openConfirmationHandler,
    closeModalHandler: closeConfirmationHandler,
    submitModalHandler: submitConfirmationHandler,
  } = useModal(customerDeleteHandler);

  if (
    isOptionsLoading ||
    (id && (isLoading || isCreating || isUpdating || isDeleting))
  ) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box maxWidth={1040}>
      {id && (
        <ActivityLogSlideInPanel
          open={showActivityLog}
          handleClose={() => setShowActivityLog(false)}
          documentId={id!}
          documentType={ActivityLogDocumentType.Customer}
        />
      )}
      <Box display="flex">
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        <Box marginLeft="auto">
          <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
            <IconButton
              onClick={() => {
                setShowActivityLog(true);
              }}
              sx={{
                color: (theme) => theme.palette.grey[500],
                padding: 0,
                marginLeft: "4px",
              }}
            >
              <RestoreOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" flexGrow={1} mt={3}>
        {(permissions?.customer?.update || permissions?.customer?.delete) && (
          <CustomizedMenuOptions
            size="small"
            label={"ตัวเลือก"}
            options={selectModifyOptions}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerText) {
                case "แก้ไข":
                  setDisabled(false);
                  break;
                case "ลบ":
                  openConfirmationHandler();
                  break;

                default:
                  break;
              }
            }}
            disabled={!id}
          />
        )}
      </Box>
      <FormProvider {...methods}>
        <ContactInfo type="customer" generateUniqueId={generateUniqueId} />
        <ContactDetail type="customer" />
        <ContactChannel />
        <ContactTag type="customer" />
        <ContactRemark />
        <CustomizedBox>
          <CustomizedTab tabs={tabs} currentTab={currentTab} subtab />
          {renderTab(tab)}
        </CustomizedBox>
        <BottomNavbar>
          {!disabled && (
            <Stack direction="row" spacing={1} alignItems="center">
              {!!id && (
                <CustomizedButton
                  variant="outlined"
                  title={t("button.cancel")}
                  onClick={editCancelHandler}
                  disabled={isCreating || isUpdating}
                />
              )}
              <CustomizedButton
                variant="contained"
                title={t("button.save")}
                onClick={handleSubmit(
                  id ? customerUpdateHandler : customerCreateHandler
                )}
                disabled={isCreating || isUpdating}
              />
            </Stack>
          )}
        </BottomNavbar>
      </FormProvider>
      <ConfirmationModal
        title={`ยืนยันการลบ${t("contact.contact_type.customer")}`}
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </Box>
  );
};

export default CustomerContainer;
