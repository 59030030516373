import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { GraphQLClient } from "graphql-request";
import { useSnackbar } from "notistack";

import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

// import CustomizedButton from "../../../components/Custom/CustomizedButton";
import TotalBox from "../../../components/UI/TotalBox";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import ItemTable from "../../../components/Table/Inventory/Item";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { itemSchema } from "../../../components/Form/Inventory/Item/schema";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import ExporterInventoryModal from "../../../components/UI/Modal/ExporterInventoryModal";

import { ISkuDetail } from "../../../types/Inventory/item";
import {
  IBreadcrumbsAndMenu,
  ICardList,
  IExporterInven,
  IMenuOption,
  ITab,
} from "../../../types/global";
import {
  ExportGenerateInput,
  ItemType,
  useGenerateInventoryExportQuery,
  usePreOrderQuotaAvaialbleForStockCardQuery,
  PreOrderQuotaAvaialbleForStockCardQuery,
} from "../../../generated/inventory";
import { GET_PREORDER_QUOTA_FOR_STOCK_CARD } from "../../../services/Inventory/PreOrderQuota/queries";
import { branchInfoFromSessionStorage } from "../../../utils/Global";
import { ITEMS_COUNT_AGGRID } from "../../../services/AgGrid/InventoryAggrid";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useModal } from "../../../hooks/use-modal";
import { useStateContext } from "../../../contexts/auth-context";

const Item = () => {
  const gridRef = useRef<AgGridReact<ISkuDetail>>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const [itemTypeCount, setItemTypeCount] = useState<
    { type: string; count: string }[]
  >([]);
  const [exportInput, setExportInput] = useState<ExportGenerateInput | null>(
    null
  );
  const { enqueueSnackbar } = useSnackbar();
  const { branch_id } = branchInfoFromSessionStorage();

  const {
    state: { permissions },
  } = useStateContext();

  const defaultOptions = useMemo(() => {
    const options = [];
    if (permissions?.item?.create) {
      options.push({
        value: t("inventory.items.import.index"),
      });
    }
    if (permissions?.inventory_export?.view) {
      options.push({
        value: t("inventory.items.export.index"),
      });
    }
    return options;
  }, [permissions?.item?.create, permissions?.inventory_export?.view, t]);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.items.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("inventory.all"),
      path: `${pathname}`,
    },
    {
      label: t("inventory.type.normal"),
      path: `${pathname}?filter=normal`,
    },
    {
      label: t("inventory.type.variant"),
      path: `${pathname}?filter=variant`,
    },
    {
      label: t("inventory.type.bundle"),
      path: `${pathname}?filter=bundle`,
    },
    {
      label: t("inventory.type.service"),
      path: `${pathname}?filter=service`,
    },
    {
      label: t("status.in_active"),
      path: `${pathname}?filter=in_active`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const createOptions = [
    { value: t("inventory.type.normal") },
    { value: t("inventory.type.variant") },
    { value: t("inventory.type.bundle") },
    { value: t("inventory.type.service") },
  ];

  const graphqlClient: GraphQLClient = createGraphQLClientWithMiddleware("wms");

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const sumValues = useCallback(
    async (type: string): Promise<string> => {
      let count = 0;
      const filterType =
        type === "all"
          ? {}
          : {
              type: {
                filterType: "set",
                values: [type],
              },
            };
      try {
        const { ItemsView } = await graphqlClient.request(ITEMS_COUNT_AGGRID, {
          aggridInput: {
            startRow: 0,
            endRow: 999999,
            filterModel: filterType,
            sortModel: [],
          },
        });
        count = ItemsView.count as number;
      } catch (err) {
        // Handle the error if needed
      }
      return count.toLocaleString() || "-";
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getPreOrderQuotaValues = useCallback(
    async () => {
      try {
        const { PreOrderQuotaAvaialbleForStockCard } =
          await graphqlClient.request<PreOrderQuotaAvaialbleForStockCardQuery>(
            GET_PREORDER_QUOTA_FOR_STOCK_CARD,
            {
              input: {
                branch_id: parseInt(branch_id ?? "-1"),
              },
            }
          );
        return PreOrderQuotaAvaialbleForStockCard?.toLocaleString() ?? "-";
      } catch (err) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [branch_id]
  );

  const CardList: ICardList[] = useMemo(
    () => [
      {
        title: t("inventory.all"),
        field: "all",
      },
      {
        title: t("inventory.type.normal"),
        field: "normal",
        borderColor: "#BF8CDE",
      },
      {
        title: t("inventory.type.variant"),
        field: "variant",
        borderColor: "#BF8CDE",
      },
      {
        title: t("inventory.type.bundle"),
        field: "bundle",
        borderColor: "#BF8CDE",
      },
      {
        title: t("inventory.type.service"),
        field: "service",
        borderColor: "#BF8CDE",
      },
      {
        title: t("inventory.preorder_quota.preorder"),
        field: "preorder_quota",
        borderColor: "#BF8CDE",
      },
    ],
    [t]
  );

  const { refetch: refetchData, isLoading: isExporting } =
    useGenerateInventoryExportQuery(
      graphqlClient,
      {
        input: exportInput,
      },
      {
        enabled: false,
      }
    );

  const exportHandler = async (data: IExporterInven) => {
    if (data) {
      const { type, start_date, end_date } = data;
      setExportInput({
        report_type: type,
        start_date: start_date,
        end_date: end_date,
      });
    }
  };

  useEffect(() => {
    const fetchDataAndOpenWindow = async () => {
      try {
        const { data: exportData } = await refetchData();
        window.open(exportData?.GenerateExportedDataNoQueue || "", "_blank");
        enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
          variant: "success",
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    };

    if (exportInput !== null) {
      fetchDataAndOpenWindow();
    }
  }, [enqueueSnackbar, exportInput, refetchData, t]);

  useEffect(() => {
    const fetchData = async () => {
      for (const list of CardList) {
        if (list.field === "preorder_quota") continue;
        const result = await sumValues(list.field);
        setItemTypeCount((prev) => [
          ...prev,
          { type: list.field, count: result },
        ]);
      }
      const preorderQuota = await getPreOrderQuotaValues();
      if (typeof preorderQuota === "string") {
        setItemTypeCount((prev) => [
          ...prev,
          { type: "preorder_quota", count: preorderQuota },
        ]);
      }
    };

    fetchData();
  }, [CardList, sumValues, getPreOrderQuotaValues]);

  const renderCard = (list: ICardList[]) =>
    list.map((menu: ICardList) => {
      const value =
        itemTypeCount.find((item) => item.type === menu.field)?.count || "0";
      if (menu.field === "preorder_quota" && value === "-") {
        return null;
      }
      return (
        <Grid item xs={12} sm={6} md={4} lg={2} xl={2} key={menu.title}>
          <TotalBox
            title={menu.title}
            value={value}
            borderColor={menu.borderColor}
          />
        </Grid>
      );
    });

  const onFilterReset = () => {
    if (gridRef) {
      gridRef.current?.api.setFilterModel({});
    }
  };

  if (exportInput && isExporting) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container spacing={1}>
        {renderCard(CardList)}
      </Grid>
      <HeaderLayout>
        <Typography variant="h5">{t("inventory.items.index")}</Typography>
        <Box display="flex" gap={1}>
          {permissions?.item?.create && (
            <CustomizedMenuOptions
              label={t("inventory.items.add")}
              variant="contained"
              options={createOptions}
              onSelect={(e) => {
                const value = e.target as HTMLElement;
                switch (value.innerHTML) {
                  case t("inventory.type.normal"):
                    navigate(`/inventory/items/add?tab=item&subtab=general`, {
                      state: { ...itemSchema, type: ItemType.Normal },
                    });
                    break;
                  case t("inventory.type.variant"):
                    navigate(`/inventory/items/add?tab=item&subtab=general`, {
                      state: { ...itemSchema, type: ItemType.Variant },
                    });
                    break;
                  case t("inventory.type.bundle"):
                    navigate(`/inventory/items/add?tab=item&subtab=general`, {
                      state: { ...itemSchema, type: ItemType.Bundle },
                    });
                    break;
                  case t("inventory.type.service"):
                    navigate(`/inventory/items/add?tab=item&subtab=general`, {
                      state: { ...itemSchema, type: ItemType.Service },
                    });
                    break;
                  default:
                    break;
                }
              }}
              endIcon={<AddIcon />}
            />
          )}
          {(permissions?.item?.create ||
            permissions?.inventory_export?.view) && (
            <CustomizedMenuOptions
              isIcon
              options={defaultOptions}
              onSelect={(e) => {
                const value = e.target as HTMLElement;
                switch (value.innerText) {
                  case `${t("inventory.items.import.index")}`:
                    navigate(`${pathname}/import`);
                    break;
                  case `${t("inventory.items.export.index")}`:
                    openModalHandler();
                    break;
                  default:
                }
              }}
            />
          )}
        </Box>
      </HeaderLayout>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
        <CustomizedButton
          title={t("button.reset_filter")}
          variant="outlined"
          onClick={onFilterReset}
          sx={{ height: "33px" }}
        />
      </Box>
      <ItemTable gridRef={gridRef} />
      <ExporterInventoryModal
        open={modal}
        closeModalHandler={closeModalHandler}
        exportHandler={exportHandler}
      />
    </>
  );
};

export default Item;
