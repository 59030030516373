import { useFormContext, useWatch } from "react-hook-form";
import { Box, Stepper, Step, StepLabel } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { IPreOrderQuotaSetting } from "../../../../../types/Inventory/PreOrderQuota";

const CustomStepIcon = (props: {
  active: boolean | undefined;
  completed: boolean | undefined;
}) => {
  const { active, completed } = props;
  if (active) {
    return <RadioButtonCheckedIcon color="primary" />;
  }
  if (completed) {
    return <CheckCircleIcon color="success" />;
  }
  return <CircleIcon color="disabled" />;
};

const PreOrderQuotaHeaderSteper = () => {
  const { control } = useFormContext<IPreOrderQuotaSetting>();

  const tab = useWatch({ control, name: "tab" });

  const currentStep = tab === "rootItem" ? 0 : 1;

  const steps = ["ระบุสินค้า/สาขา", "ระบุจำนวน"];

  return (
    <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
      <Box sx={{ width: "280px" }}>
        <Stepper activeStep={currentStep}>
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={(stepProps) => (
                    <CustomStepIcon
                      active={stepProps.active}
                      completed={stepProps.completed}
                    />
                  )}
                  sx={{
                    "& .MuiStepLabel-label.Mui-completed": {
                      color: "#22915A",
                    },
                    "& .MuiStepLabel-label.Mui-active": {
                      color: (theme) => theme.palette.primary.main,
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </Box>
  );
};

export default PreOrderQuotaHeaderSteper;
