import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";

import { useDisable } from "../../../../hooks/use-disable";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledSwitch from "../../../Controller/ControlledSwitch";

import { IPreOrderQuotaSetting } from "../../../../types/Inventory/PreOrderQuota";

const PreorderQuotaSettingInfo = () => {
  const { t } = useTranslation();
  const [disabled] = useDisable();
  const {
    control,
    formState: { errors },
  } = useFormContext<IPreOrderQuotaSetting>();

  return (
    <CustomizedBox>
      <Typography
        fontWeight={600}
        mb={2}
        color={Boolean(errors.name) ? "error.main" : undefined}
      >
        {t("inventory.preorder_quota.info.index")}
      </Typography>
      <Grid
        container
        spacing={1.5}
        sx={
          disabled
            ? {
                "& > .MuiGrid-item": {
                  paddingLeft: "0 !important",
                },
              }
            : {}
        }
      >
        <Grid item xs={12} sm={12} md={6}>
          <ControlledTextField
            control={control}
            name="name"
            label={t("inventory.preorder_quota.info.name")}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            viewMode={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledTextField
            control={control}
            name="description"
            label={t("inventory.preorder_quota.info.description")}
            error={Boolean(errors.description)}
            helperText={errors.description?.message}
            viewMode={disabled}
          />
        </Grid>
      </Grid>
      <Box display={"flex"} mt={1} width={220} alignItems={"center"} gap={2}>
        <Typography fontWeight={600}>
          {t("inventory.preorder_quota.info.status")}
        </Typography>
        <ControlledSwitch
          control={control}
          name="is_active"
          disabled={disabled}
          label={t("inventory.preorder_quota.info.status_active")}
        />
      </Box>
    </CustomizedBox>
  );
};

export default PreorderQuotaSettingInfo;
