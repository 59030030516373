import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ColDef, GetRowIdParams, GridReadyEvent } from "ag-grid-community";
import { IServerSideDatasource } from "ag-grid-community";
import { ItemType } from "../../../generated/inventory";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { ITEM_VIEW } from "../../../services/AgGrid/InventoryAggrid";
import CheckboxAggridModal from "./CheckboxAggridModal";
import { useItemColumnDefs } from "../../Table/Inventory/Item/useItemColumnDefs";

type CheckedItemModalProps = {
  showSelectItem: boolean;
  closeItemTable: () => void;
  finishItemsSelect: (data: any) => void;
  itemIds: string[];
  setItemIds: Dispatch<SetStateAction<string[]>>;
  itemIdsSnapshot: string[];
  setItemIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  rowData?: any[];
  isLoading?: boolean;
  rowSelection: "single" | "multiple";
  useAltButtons?: boolean;
  type?: string;
  lockRows?: (string | undefined)[];
  titleColor?: string;
};

const CheckedItemModal = ({
  showSelectItem,
  closeItemTable,
  finishItemsSelect,
  itemIds,
  setItemIds,
  itemIdsSnapshot,
  setItemIdsSnapshot,
  isLoading,
  rowSelection,
  useAltButtons,
  lockRows,
  type,
  titleColor,
}: CheckedItemModalProps) => {
  const { t } = useTranslation();
  const selectGridRef = useRef();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  // const { data: tagList } =
  //   useInventoryTagsFindByEntityQuery<InventoryTagsFindByEntityQuery>(
  //     graphQLClientWithHeaderItem,
  //     {
  //       entityName: ItemEntityType.Item,
  //     }
  //   );

  const columnDefs: ColDef[] = useItemColumnDefs(false, type);

  const datasource: IServerSideDatasource = {
    async getRows(params) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { status, tag_list, ...otherFilter } = filterModel;

      const formatFilter = {
        ...otherFilter,
        status: status && {
          ...status,
          values: status.values.map((v: string) => parseInt(v)),
        },
        tag_list: tag_list && {
          type: "hasSome",
          filterType: "array",
          values: tag_list.values,
        },
      };

      const formatSorting = [
        ...sortModel,
        { sort: "desc", colId: "created_date" },
      ];

      try {
        const { ItemsView } = await graphQLClientWithHeaderItem.request(
          ITEM_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel: formatSorting,
            },
          }
        );
        params.success({
          rowData: ItemsView.results as any[],
          rowCount: ItemsView.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    const instanceStatus = params.api.getFilterInstance("status");
    const typeInstance = params.api.getFilterInstance("type");
    typeInstance?.setModel({
      values: [ItemType.Normal, ItemType.Variant, ItemType.Service],
    });
    instanceStatus?.setModel({ values: ["1"] });
    params.api.setServerSideDatasource(datasource);
  };

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.sku_name;
  }, []);

  return (
    <CheckboxAggridModal
      modalTitle={"สินค้า"}
      btnTitle={useAltButtons ? t("button.confirm") : t("button.add")}
      gridRef={selectGridRef}
      height={665}
      columnDefs={columnDefs}
      rowSelection={rowSelection}
      onFinishEditing={finishItemsSelect}
      modalIsOpen={showSelectItem}
      getRowId={getRowId}
      closeModal={closeItemTable}
      selectedIds={itemIds}
      setSelectedIds={setItemIds}
      idsSnapshot={itemIdsSnapshot}
      setIdsSnapshot={setItemIdsSnapshot}
      isLoading={isLoading}
      onGridReady={onGridReady}
      rowMultiSelectWithClick={true}
      keyName="sku_name"
      useAltButtons={useAltButtons}
      altBtnTitle={t("button.cancel")}
      altTitleColor={titleColor}
      lockRows={lockRows}
    />
  );
};

export default CheckedItemModal;
