import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../../services/graphqlClient";
import { CustomizedBox } from "../../../../Custom/CustomizedBox";
import ControlledRadioGroup from "../../../../Controller/ControlledRadioGroup";
import { IRadio } from "../../../../../types/global";
import { useDisable } from "../../../../../hooks/use-disable";
import { IPreOrderQuotaSetting } from "../../../../../types/Inventory/PreOrderQuota";
import PreOrderQuotaSettingSelectBranches from "./SelectBranch";
import {
  useBranchesFindAllQuery,
  BranchesFindAllQuery,
} from "../../../../../generated/general";
import { usePreOrderQuotaSetting } from "../../../../../hooks/Inventory/use-preorder-quota-setting";

const PreOrderQuotaSettingBranches = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [disabled] = useDisable();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IPreOrderQuotaSetting>();
  const { updateQuotaItemList } = usePreOrderQuotaSetting();

  const graphQLClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const {
    data: branches,
    refetch: refetchBranches,
    isStale,
  } = useBranchesFindAllQuery<BranchesFindAllQuery>(
    graphQLClientGeneral,
    undefined,
    { enabled: false, staleTime: 5 * 60 * 1000 }
  );

  const isAllowedInAllBranches = useWatch({
    control,
    name: "is_allowed_in_all_branches",
  });

  const formattedIsAllowedInAllBranches =
    isAllowedInAllBranches === true || isAllowedInAllBranches === "true";

  const branchList = useWatch({
    control,
    name: "branch_list",
  });

  const branchOptions: IRadio[] = [
    {
      label: t("inventory.preorder_quota.sentence.all_branches"),
      value: true,
    },
    {
      label: t("inventory.preorder_quota.sentence.selected_branches"),
      value: false,
    },
  ];

  const handleBranchChange = async (value: any) => {
    if (value === true || value === "true") {
      if (!branches?.BranchesFindAll?.length || isStale) {
        const { data } = await refetchBranches();
        if (data?.BranchesFindAll?.length) {
          setValue("branch_list", data?.BranchesFindAll as any);
        }
      } else {
        setValue("branch_list", branches?.BranchesFindAll as any);
      }
    } else {
      setValue("branch_list", []);
    }
    setValue("is_allowed_in_all_branches", value);
    updateQuotaItemList();
  };

  useEffect(() => {
    if (!id && formattedIsAllowedInAllBranches && !branchList?.length) {
      const getAllBranches = async () => {
        if (!branches?.BranchesFindAll?.length || isStale) {
          const { data } = await refetchBranches();
          if (data?.BranchesFindAll?.length) {
            setValue("branch_list", data?.BranchesFindAll as any);
          }
        } else {
          setValue("branch_list", branches?.BranchesFindAll as any);
        }
      };
      getAllBranches();
    }
  }, [
    id,
    branchList,
    formattedIsAllowedInAllBranches,
    setValue,
    refetchBranches,
    branches,
    isStale,
  ]);

  return (
    <CustomizedBox>
      <Box display={"flex"} mb={1} gap={0.3}>
        <Typography
          fontWeight={600}
          color={Boolean(errors.branch_list) ? "error.main" : undefined}
        >
          {t("branch.index")}
        </Typography>
        <Typography fontWeight={600} color={"error.main"}>
          *
        </Typography>
      </Box>
      {id && (
        <Box display="flex" alignItems="center" gap={1} my={2}>
          <CheckIcon
            fontSize="small"
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          />
          <Typography>
            {formattedIsAllowedInAllBranches
              ? t("inventory.preorder_quota.sentence.all_branches")
              : t("inventory.preorder_quota.sentence.selected_branches")}
          </Typography>
        </Box>
      )}
      {!id && (
        <ControlledRadioGroup
          control={control}
          name="is_allowed_in_all_branches"
          radioLists={branchOptions}
          disabled={disabled}
          row
          onChange={(_, value) => {
            handleBranchChange(value);
          }}
          disableDefaultOnChange
        />
      )}
      {!formattedIsAllowedInAllBranches && (
        <PreOrderQuotaSettingSelectBranches />
      )}
    </CustomizedBox>
  );
};

export default PreOrderQuotaSettingBranches;
