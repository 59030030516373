import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

import {
  Box,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledSwitch from "../../../Controller/ControlledSwitch";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import { useNavigate, useParams } from "react-router-dom";
import { useDisable } from "../../../../hooks/use-disable";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import CustomizedChips from "../../../Custom/CustomizedChips";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  SalesModelType,
  SalesReferenceDocumentType,
  SalesUniqueIdGenerateQuery,
  useSalesUniqueIdGenerateQuery,
  SalesOrderCanCreatePurchaseRequestQuery,
  useSalesOrderCanCreatePurchaseRequestQuery,
} from "../../../../generated/sales";
import { useCallback, useEffect } from "react";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import { IApprovalTemplate, IMenuOption } from "../../../../types/global";
import { useSalesOrderOption } from "../../../../hooks/Sales/SalesOrder/use-sales-order-option";
import { useSalesOrderCreate } from "../../../../hooks/Sales/SalesOrder/use-sales-order-create";
import { useModal } from "../../../../hooks/use-modal";
import ExternalReferenceModal from "../../../UI/Modal/ExternalReferenceModal";
import { IExternalProps } from "../../../../types/Sales";
import {
  copySalesOrderFormatter,
  createCashSalesFromOrder,
  createSalesInvoiceFromOrder,
  createSalesReturnFromOrder,
  createCustomerDepositFromOrder,
} from "../../../../utils/Formatter/Sales/SalesOrder";
import { useConfirmation } from "../../../../hooks/use-confirmation";
import Confirmation from "../../../UI/Confirmation";
import { ISalesOrder } from "../../../../types/Sales/salesOrder";
import ApproverBox from "../../../UI/ApproverBox";
import { createPurchaseRequestFromSalesOrder } from "../../../../utils/Formatter/Purchase/PurchaseRequest";
import { useStateContext } from "../../../../contexts/auth-context";
import SalesPersonHeader from "../SalesPerson";
import { createDeliveryOrderFromSales } from "../../../../utils/Formatter/Logistic/DeliveryOrder";

type Props = {
  editClickHandler: () => void;
  externalReferenceHandler: (data: IExternalProps) => void;
  refetchPreOrderQuotaAvailable: () => Promise<any>;
  cancelHandler: (data: ISalesOrder) => Promise<void>;
  approverList: IApprovalTemplate[];
  approvalStep?: number | null;
};

const SalesOrderHeader = ({
  editClickHandler,
  externalReferenceHandler,
  refetchPreOrderQuotaAvailable,
  cancelHandler,
  approverList,
  approvalStep,
}: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<any>();
  const [disabled] = useDisable();
  const navigate = useNavigate();
  const {
    state: { authUser, permissions },
  } = useStateContext();

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const reference_document_list = useWatch({
    control,
    name: "reference_document_list",
  });

  const external_reference_no = useWatch({
    control,
    name: "external_reference_no",
  });

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const use_preorder_quota = useWatch({
    control,
    name: "use_preorder_quota",
  });

  const printOptions: IMenuOption[] = [
    {
      value: `พิมพ์${t("sales.order.index")}`,
      disabled: !id,
    },
  ];

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { refetch: refetchUniqueId } =
    useSalesUniqueIdGenerateQuery<SalesUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: SalesModelType.SalesOrder,
        usePreorderQuota: use_preorder_quota,
      },
      {
        enabled: false,
        onSuccess: (data) => {
          setValue("unique_id", data?.UniqueIdGenerate ?? "");
        },
      }
    );

  const { data: prCreateValidation } =
    useSalesOrderCanCreatePurchaseRequestQuery<SalesOrderCanCreatePurchaseRequestQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: parseInt(id ?? ""),
        },
      },
      {
        enabled:
          Boolean(id) &&
          use_preorder_quota &&
          (status === "approved" || status === "finished"),
      }
    );

  const generateUniqueId = useCallback(async () => {
    await refetchUniqueId();
  }, [refetchUniqueId]);

  useEffect(() => {
    if (!id) {
      const getUniqueId = async () => {
        await new Promise((resolve) => setTimeout(resolve, 100));
        await generateUniqueId();
      };
      getUniqueId();
    }
  }, [generateUniqueId, id]);

  const selectModifyOptions = useSalesOrderOption(status);
  const { selectCreateOptions, disabledCreateOptions } = useSalesOrderCreate(
    status,
    use_preorder_quota,
    prCreateValidation?.SalesOrderCanCreatePurchaseRequest
  );

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const submitModalHandler = (data: IExternalProps) => {
    externalReferenceHandler(data);
    closeModalHandler();
  };

  const copyDocumentHandler = async () => {
    const salesOrder = getValues();
    navigate("/sales/order/add", {
      state: await copySalesOrderFormatter(salesOrder, authUser),
    });
  };

  const disablePreOrderQuotaHandler = async () => {
    setValue("sales_type", undefined);
    setValue("use_preorder_quota", false);
    setValue("item_list", []);
    await new Promise((resolve) => setTimeout(resolve, 0));
    await generateUniqueId();
  };

  const {
    confirmation: copyConfirmation,
    openConfirmationHandler: openCopyConfirmation,
    closeConfirmationHandler: closeCopyConfirmation,
    submitConfirmationHandler: submitCopyConfirmation,
  } = useConfirmation(copyDocumentHandler);

  const {
    confirmation: cancelConfirmation,
    openConfirmationHandler: openCancelConfirmation,
    closeConfirmationHandler: closeCancelConfirmation,
    submitConfirmationHandler: submitCancelConfirmation,
  } = useConfirmation(cancelHandler);

  const {
    confirmation: disablePreOrderQuotaConfirmation,
    openConfirmationHandler: openDisablePreOrderQuotaConfirmation,
    closeConfirmationHandler: closeDisablePreOrderQuotaConfirmation,
    submitConfirmationHandler: submitDisablePreOrderQuotaConfirmation,
  } = useConfirmation(disablePreOrderQuotaHandler);

  return (
    <Box maxWidth={1040} mt={2}>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">{t("sales.order.index")}</Typography>
            <CustomizedStatus status={status} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1.5} justifyContent={"flex-end"}>
            <Grid item xs={5.5} sm={5.5} md={4} lg={4} xl={4}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"พิมพ์เอกสาร"}
                options={printOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  if (value.innerText === `พิมพ์${t("sales.order.index")}`) {
                    navigate(`/sales/order/${id}/pdf`);
                  }
                }}
                disabled={
                  !id || status === "cancelled" || status === "not_approved"
                }
              />
            </Grid>
            {selectModifyOptions.length > 0 && (
              <Grid item xs={5.5} sm={5.5} md={4} lg={4} xl={4}>
                <CustomizedMenuOptions
                  fullWidth
                  size="medium"
                  label={"ตัวเลือก"}
                  options={selectModifyOptions}
                  onSelect={(e) => {
                    const value = e.target as HTMLElement;
                    switch (value.innerText) {
                      case "แก้ไข":
                        editClickHandler();
                        break;
                      case "คัดลอก":
                        openCopyConfirmation();
                        break;
                      case "ยกเลิก":
                        openCancelConfirmation();
                        break;
                      case t("sales.external_reference_no"):
                        openModalHandler();
                        break;
                      default:
                        break;
                    }
                  }}
                  disabled={!id}
                />
              </Grid>
            )}
            {selectCreateOptions.length > 0 && (
              <Grid item xs={5.5} sm={5.5} md={4} lg={4} xl={4}>
                <CustomizedMenuOptions
                  fullWidth
                  size="medium"
                  label={"สร้าง"}
                  options={selectCreateOptions}
                  onSelect={async (e) => {
                    const value = e.target as HTMLElement;
                    const salesOrder = getValues();
                    switch (value.innerText) {
                      case `${t("sales.customer_deposit.index")}`:
                        navigate("/sales/customer-deposit/add", {
                          state: createCustomerDepositFromOrder(salesOrder),
                        });
                        break;
                      case `${t("sales.cash_sales.index")}`:
                        navigate("/sales/cash-sales/add", {
                          state: createCashSalesFromOrder(salesOrder),
                        });
                        break;
                      case `${t("sales.invoice.index")}`:
                        navigate("/sales/invoice/add", {
                          state: createSalesInvoiceFromOrder(salesOrder),
                        });
                        break;
                      case `${t("sales.return.index")}`:
                        navigate("/sales/return/add", {
                          state: createSalesReturnFromOrder(salesOrder),
                        });
                        break;
                      case `${t("logistic.delivery_order.index")}`:
                        navigate("/logistic/delivery-order/add", {
                          state: await createDeliveryOrderFromSales(
                            salesOrder,
                            SalesReferenceDocumentType.SalesOrder
                          ),
                        });
                        break;
                      case `${t("purchase.request.index")}`:
                        navigate("/purchase/request/add", {
                          state:
                            createPurchaseRequestFromSalesOrder(salesOrder),
                        });
                        break;
                      default:
                    }
                  }}
                  disabled={disabledCreateOptions}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1.5} alignItems="center">
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>{t("sales.unique_id")}</Typography>
            </Grid>
            <Grid item xs={11} sm={11} lg={7}>
              <ControlledTextField
                name="unique_id"
                control={control}
                placeholder={t("sales.unique_id")}
                error={Boolean(errors?.unique_id)}
                helperText={
                  errors?.unique_id && errors?.unique_id.message?.toString()
                }
                disabled={Boolean(id) && status && status !== "draft"}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={1}>
              {(!Boolean(id) ||
                (status &&
                  status === "draft" &&
                  permissions?.sales_order?.update)) && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton
                    onClick={generateUniqueId}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("sales.reference_document_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <Box mx={disabled ? 1.5 : 0}>
                {reference_document_list && reference_document_list.length > 0
                  ? reference_document_list.map((reference: any) => (
                      <CustomizedChips
                        key={`${reference.document_id}-${reference.document_unique_id}-${reference.document_type}`}
                        onClick={() => {
                          if (reference.document_type === "quotation") {
                            window.open(
                              `/sales/quotation/${encodeURIComponent(
                                reference.document_id
                              )}`,
                              "_blank"
                            );
                          }
                        }}
                        value={reference.document_unique_id}
                        color="primary"
                      />
                    ))
                  : "-"}
              </Box>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("sales.reference_no")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledTextField
                name="reference_no"
                control={control}
                placeholder={t("sales.reference_no")}
                error={Boolean(errors?.reference_no)}
                helperText={
                  errors?.reference_no &&
                  errors?.reference_no.message?.toString()
                }
                disabled={disabled}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("sales.external_reference_no")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} lg={7}>
              <Typography mx={disabled ? 1.5 : 0}>
                {external_reference_no ? external_reference_no : "-"}
              </Typography>
            </Grid>
            <Grid item xs={1} />
            {id && status !== "draft" && approverList.length > 0 && (
              <>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography fontWeight={600}>
                    {t("sales.approval")}
                  </Typography>
                </Grid>
                <Grid item xs={11} sm={11} md={7}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      mt: 1,
                      whiteSpace: "nowrap",
                      flexWrap: "wrap",
                      mx: disabled ? 1 : 0,
                    }}
                  >
                    {approverList.map((approval, index) => {
                      const formatApproval = approval as IApprovalTemplate;
                      return (
                        <ApproverBox
                          key={index}
                          approval={formatApproval}
                          index={index}
                          approvalStep={approvalStep}
                        />
                      );
                    })}
                  </Box>
                </Grid>
                <Grid item xs={1} />
              </>
            )}
            <Grid item xs={12} sm={12} lg={4}>
              <Typography fontWeight={600}>
                {t("sales.employee_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} lg={7}>
              <Box mx={disabled ? 1.5 : 0}>
                <SalesPersonHeader />
              </Box>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} mt={isSmall ? 2 : 0}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Box sx={{ ml: 1 }}>
                <ControlledSwitch
                  control={control}
                  name="use_preorder_quota"
                  disabled={disabled}
                  label={t("inventory.preorder_quota.preorder")}
                  onChange={async (e, field) => {
                    if (!e.target.checked) {
                      openDisablePreOrderQuotaConfirmation();
                      return;
                    }
                    field.onChange(e);
                    await refetchPreOrderQuotaAvailable();
                    await new Promise((resolve) => setTimeout(resolve, 0));
                    await generateUniqueId();
                    setValue("sales_type", "โควตาพรีออเดอร์");
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                label={t("date.created_date")}
                disabled={true}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                label={t("date.issue_date")}
                disabled={disabled}
                viewMode={disabled}
                onAfterChange={async () => {
                  if (!use_preorder_quota) return;
                  await new Promise((resolve) => setTimeout(resolve, 100));
                  await refetchPreOrderQuotaAvailable();
                }}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={1.5} justifyContent="flex-end" mt={0}>
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <ControlledDatePicker
                name="delivery_date"
                control={control}
                label={t("date.delivery_date")}
                disabled={disabled}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <ControlledDatePicker
                name="due_date"
                control={control}
                label={t("date.to_date")}
                disabled={disabled}
                viewMode={disabled}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={1.5} justifyContent="flex-end" mt={0}>
            <Grid item xs={6} sm={6} md={3} lg={4}>
              <ControlledTextField
                type="number"
                label={t("sales.credit_day")}
                control={control}
                name="credit_day"
                onChange={(e, field) => {
                  const parseIntValue = parseInt(e.target.value);
                  field.onChange(parseIntValue);
                }}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                viewMode={disabled}
                noDecimal
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ExternalReferenceModal
        open={modal}
        handleClose={closeModalHandler}
        action={submitModalHandler}
      />
      <Confirmation
        title="ยกเลิกใบสั่งขาย"
        message="หากทำการยกเลิกเอกสารจะไม่สามารถย้อนกลับได้"
        open={cancelConfirmation}
        handleClose={closeCancelConfirmation}
        action={submitCancelConfirmation}
      />
      <Confirmation
        title="ยืนยันหากต้องการคัดลอกเอกสาร"
        open={copyConfirmation}
        handleClose={closeCopyConfirmation}
        action={submitCopyConfirmation}
      />
      <Confirmation
        title="การเปลี่ยนตัวเลือก"
        message="หากเปลี่ยนตัวเลือก ข้อมูลที่ระบุด้านล่างจะถูกล้างค่า"
        open={disablePreOrderQuotaConfirmation}
        handleClose={closeDisablePreOrderQuotaConfirmation}
        action={submitDisablePreOrderQuotaConfirmation}
      />
    </Box>
  );
};

export default SalesOrderHeader;
