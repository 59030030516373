import { useEffect, useState } from "react";
import { IHeaderTable } from "../../types/global";

export const usePreOrderQuotaRootItemListHeader = (disabled: boolean) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    const defaultHeaders: IHeaderTable[] = [
      {
        thaiLabel: "",
        width: 30,
        align: "center",
      },
      {
        thaiLabel: "สินค้า",
        width: 520,
        align: "left",
      },
      {
        thaiLabel: "หน่วย",
        label: "Unit",
        width: 150,
        align: "left",
      },
    ].filter(Boolean) as IHeaderTable[];

    if (!disabled) {
      setHeaders([
        ...defaultHeaders,
        {
          label: "",
          thaiLabel: "",
          width: 30,
          align: "left",
        },
      ]);
    } else {
      setHeaders(defaultHeaders);
    }
  }, [disabled]);

  return headers;
};

export const usePreOrderItemQuotaListHeader = (
  disabled: boolean,
  hasSharedQuota: boolean | string,
  isEditPage: boolean
) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);
  const has_shared_quota = hasSharedQuota === true || hasSharedQuota === "true";

  useEffect(() => {
    const defaultHeaders: IHeaderTable[] = [
      {
        thaiLabel: "",
        width: 30,
        align: "center",
      },
      {
        thaiLabel: "รหัสสาขา",
        label: "Branch Unique ID",
        width: !isEditPage && has_shared_quota ? 520 : !isEditPage ? 350 : 250,
        align: "left",
      },
      {
        thaiLabel: "สาขา",
        label: "Branch Name",
        width: isEditPage && !has_shared_quota ? 130 : 150,
        align: "left",
      },
    ].filter(Boolean) as IHeaderTable[];

    setHeaders(defaultHeaders);

    if (!has_shared_quota) {
      setHeaders(
        [
          ...defaultHeaders,
          {
            thaiLabel: "จำนวน",
            label: "Quantity",
            width: 125,
            align: "left",
          },
          isEditPage
            ? {
                thaiLabel: "สั่งแล้ว",
                label: "Ordered Quantity",
                width: 125,
                align: "left",
              }
            : undefined,
          isEditPage
            ? {
                thaiLabel: "คงเหลือ",
                label: "Available Quantity",
                width: 125,
                align: "left",
              }
            : undefined,
        ].filter(Boolean) as IHeaderTable[]
      );
    } else {
      setHeaders(
        [
          ...defaultHeaders,
          isEditPage
            ? {
                thaiLabel: "สั่งแล้ว",
                label: "Ordered Quantity",
                width: 150,
                align: "left",
              }
            : undefined,
        ].filter(Boolean) as IHeaderTable[]
      );
    }
  }, [disabled, hasSharedQuota, has_shared_quota, isEditPage]);

  return headers;
};

export const preOrderItemQuotaAutofillHeader: IHeaderTable[] = [
  { thaiLabel: "สินค้า", align: "left", width: 200 },
  { thaiLabel: "จำนวน", align: "left", width: 100 },
];

export const textAlignToJustify = (align?: string) => {
  switch (align) {
    case "left":
      return "flex-start";
    case "right":
      return "flex-end";
    default:
      return "center";
  }
};
