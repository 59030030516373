import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { IPromotion } from "../../../../../types/Marketing/Promotion";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import PromotionSelectBranchTable from "../../../../Table/Marketing/Promotion/SelectBranch";
import CustomizedButton from "../../../../Custom/CustomizedButton";
import { useModal } from "../../../../../hooks/use-modal";
import CheckedBranchModal from "../../../../UI/Modal/CheckedBranchModal";
import { Box, FormHelperText } from "@mui/material";
import { useDisable } from "../../../../../hooks/use-disable";

const PromotionSelectBranch = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IPromotion>();

  const watchIsApplied = useWatch({ control, name: "is_applied" });
  const isApplied = Boolean(watchIsApplied);

  const { t } = useTranslation();
  const [disabled] = useDisable();

  const { fields, replace, remove } = useFieldArray({
    control,
    name: "branch_list",
  });

  const [branchIds, setBranchIds] = useState<string[]>([]);
  const [branchIdsSnapshot, setBranchIdsSnapshot] = useState<string[]>([]);

  const { modal, closeModalHandler, openModalHandler } = useModal();

  const addBranchHandler = (data: any) => {
    replace(data);
    closeModalHandler();
  };

  const onRemoveHandler = (index: number) => {
    remove(index);
  };

  useEffect(() => {
    if (fields && fields.length > 0) {
      const uniqueIdList = fields.map(({ unique_id }) => unique_id);
      setBranchIds(uniqueIdList);
      setBranchIdsSnapshot(uniqueIdList);
    } else {
      setBranchIds([]);
      setBranchIdsSnapshot([]);
    }
  }, [fields]);

  return (
    <>
      <Box mt={1}>
        <PromotionSelectBranchTable
          rowData={fields}
          onRemoveHandler={onRemoveHandler}
        />
        {!disabled && !isApplied && (
          <CustomizedButton
            variant="outlined"
            title={`${t("button.add")}${t("branch.index")}`}
            onClick={openModalHandler}
            addIcon
          />
        )}
        {Boolean(errors.branch_list) && (
          <FormHelperText error sx={{ mt: 1 }}>
            {errors.branch_list?.message}
          </FormHelperText>
        )}
      </Box>
      <CheckedBranchModal
        branchIds={branchIds}
        branchIdsSnapshot={branchIdsSnapshot}
        setBranchIds={setBranchIds}
        setBranchIdsSnapshot={setBranchIdsSnapshot}
        showSelectBranch={modal}
        rowSelection="multiple"
        closeBranchTable={closeModalHandler}
        finishBranchSelect={addBranchHandler}
        useAltButtons
        titleColor={"#333333"}
      />
    </>
  );
};

export default PromotionSelectBranch;
