import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useParams } from "react-router-dom";

import ControlledRadioGroup from "../../../../Controller/ControlledRadioGroup";
import { IRadio } from "../../../../../types/global";
import { formatNumber } from "../../../../../utils/Global";
import { useDisable } from "../../../../../hooks/use-disable";
import { CustomizedBox } from "../../../../Custom/CustomizedBox";
import ControlledNumberTextField from "../../../../Controller/ControlledNumberTextField";
import CustomizedButton from "../../../../Custom/CustomizedButton";
import CustomizedChips from "../../../../Custom/CustomizedChips";
import ItemQuotaList from "../../../../Table/Inventory/PreOrderQuota/ItemQuotaList";

import { IPreOrderQuotaSetting } from "../../../../../types/Inventory/PreOrderQuota";

const PreOrderQuotaSettingItemQuota = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [disabled] = useDisable();
  const {
    control,
    formState: { errors },
  } = useFormContext<IPreOrderQuotaSetting>();

  const hasSharedQuota = useWatch({ control, name: "has_shared_quota" });

  const sharedQuota = useWatch({ control, name: "shared_quota" });
  const totalOrderedQty = useWatch({ control, name: "total_ordered_qty" });

  const formattedHasSharedQuota =
    hasSharedQuota === true || hasSharedQuota === "true";

  const quotaOptions: IRadio[] = [
    {
      label: t("inventory.preorder_quota.shared_quota"),
      value: true,
    },
    {
      label: t("inventory.preorder_quota.sentence.set_per_branch"),
      value: false,
    },
  ];

  return (
    <CustomizedBox>
      <Box display={"flex"} mb={1} gap={0.3}>
        <Typography fontWeight={600}>{t("inventory.quantity")}</Typography>
        <Typography fontWeight={600} color={"error.main"}>
          *
        </Typography>
        {id && (
          <CustomizedChips
            value={`${t("inventory.quantities.remaining_qty")} ${formatNumber(
              (sharedQuota ?? 0) - (totalOrderedQty ?? 0),
              0
            )} จาก ${formatNumber(sharedQuota ?? 0, 0)}`}
            variant="filled"
            sx={(theme) => ({
              marginLeft: "0.5rem",
              backgroundColor: "#F0F1FA",
              color: theme.palette.primary.main,
            })}
          />
        )}
      </Box>
      {id && (
        <Box display="flex" alignItems="center" gap={1} my={1}>
          <CheckIcon
            fontSize="small"
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          />
          <Typography>
            {formattedHasSharedQuota
              ? t("inventory.preorder_quota.shared_quota")
              : t("inventory.preorder_quota.sentence.set_per_branch")}
          </Typography>
        </Box>
      )}
      {!id && (
        <ControlledRadioGroup
          control={control}
          name="has_shared_quota"
          radioLists={quotaOptions}
          disabled={disabled}
          row
        />
      )}
      {(hasSharedQuota === true || hasSharedQuota === "true") && (
        <Grid
          container
          spacing={1.5}
          alignItems="center"
          sx={
            disabled
              ? {
                  "& > .MuiGrid-item": {
                    paddingLeft: "0 !important",
                  },
                }
              : {}
          }
        >
          <Grid item xs={12} sm={12} md={3}>
            <ControlledNumberTextField
              control={control}
              name="shared_quota"
              label={t("inventory.preorder_quota.quota")}
              error={Boolean(errors.shared_quota)}
              helperText={errors.shared_quota?.message}
              viewMode={disabled}
              decimalScale={0}
              disableNegative
              fullWidth
              required
            />
          </Grid>
        </Grid>
      )}
      <ItemQuotaList />
    </CustomizedBox>
  );
};

export default PreOrderQuotaSettingItemQuota;
