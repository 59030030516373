import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import Confirmation from "../../../UI/Confirmation";
import BottomNavbar from "../../../UI/Navbar/BottomNavbar";
import CustomizedButton from "../../../Custom/CustomizedButton";
import { useConfirmation } from "../../../../hooks/use-confirmation";
import { useDisable } from "../../../../hooks/use-disable";
import { usePreOrderQuotaSetting } from "../../../../hooks/Inventory/use-preorder-quota-setting";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  useWmsUniqueIdIsExistQuery,
  WmsUniqueIdIsExistQuery,
  WmsModelType,
} from "../../../../generated/inventory";
import { PREORDER_QUOTA_NAME_EXISTS } from "../../../../services/Inventory/PreOrderQuota/queries";
import { GraphQLClient } from "graphql-request";
type Props = {
  createHandler: (data: any) => Promise<void>;
  updateHandler: (data: any) => Promise<void>;
  cancelEditHandler: () => void;
};

const PreOrderQuotaSettingNavigateButtons = ({
  createHandler,
  updateHandler,
  cancelEditHandler,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { t } = useTranslation();
  const { updateQuotaItemList } = usePreOrderQuotaSetting();
  const { control, getValues, setValue, handleSubmit, trigger } =
    useFormContext();
  const [disabled] = useDisable();

  const currentTab = useWatch({
    control,
    name: "tab",
  });

  const unique_id = useWatch({
    control,
    name: "unique_id",
  });

  const graphqlClient: GraphQLClient = createGraphQLClientWithMiddleware("wms");

  const { refetch } = useWmsUniqueIdIsExistQuery<WmsUniqueIdIsExistQuery>(
    graphqlClient,
    {
      modelType: WmsModelType.PreorderQuotaSetting,
      uniqueId: unique_id,
    },
    {
      enabled: false,
    }
  );

  const prevTabNagivateHandler = () => {
    setValue("has_shared_quota", true);
    setValue("shared_quota", 0);
    setValue("tab", "rootItem");
    updateQuotaItemList();
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(prevTabNagivateHandler);

  const navigateQuotaTabHandler = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }
    const { data } = await refetch();
    if (data?.UniqueIdIsExist) {
      enqueueSnackbar("รหัสโควตาซ้ำกันภายในระบบ", {
        variant: "error",
      });
      return;
    }

    const { PreOrderQuotaNameExists } = await graphqlClient.request(
      PREORDER_QUOTA_NAME_EXISTS,
      {
        name: getValues("name"),
      }
    );

    if (PreOrderQuotaNameExists) {
      enqueueSnackbar("ชื่อโควตาซ้ำกันภายในระบบ", {
        variant: "error",
      });
      return;
    }

    setValue("tab", "quota");
  };

  const errorHandler = (err: any) => {
    const hasAvailableQtyError = err.item_quota_list?.some((quota_list: any) =>
      quota_list?.some((item: any) => item.available_qty?.type === "min")
    );

    const sharedQuotaError = err.shared_quota?.type === "is-gte-ordered-qty";

    if (hasAvailableQtyError || sharedQuotaError) {
      enqueueSnackbar("กรุณาระบุโควตาให้มากกว่าจํานวนที่สั่ง", {
        variant: "error",
      });
    }
  };

  const renderButton = () => {
    if (!id && currentTab === "rootItem") {
      return (
        !disabled && (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              title={t("button.cancel")}
              variant="text"
              onClick={cancelEditHandler}
            />
            <CustomizedButton
              title={t("button.next")}
              variant="contained"
              onClick={navigateQuotaTabHandler}
            />
          </Stack>
        )
      );
    }
    if (!id && currentTab === "quota") {
      return (
        !disabled && (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              title={t("button.cancel")}
              variant="text"
              onClick={cancelEditHandler}
            />
            <CustomizedButton
              title={t("button.back")}
              variant="outlined"
              onClick={openConfirmationHandler}
            />
            <CustomizedButton
              title={t("button.save")}
              variant="contained"
              onClick={handleSubmit(createHandler)}
            />
          </Stack>
        )
      );
    }
    if (id) {
      return (
        !disabled && (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              title={t("button.cancel")}
              variant="text"
              onClick={cancelEditHandler}
            />
            <CustomizedButton
              title={t("button.save")}
              variant="contained"
              onClick={handleSubmit(updateHandler, errorHandler)}
            />
          </Stack>
        )
      );
    }
  };

  return (
    <>
      {(!id || (id && !disabled)) && (
        <BottomNavbar>{renderButton()}</BottomNavbar>
      )}
      <Confirmation
        title="การเปลี่ยนตัวเลือก"
        message="หากไม่ทำการบันทึก ข้อมูลด้านล่างจะถูกล้างค่า"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default PreOrderQuotaSettingNavigateButtons;
