import { useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import { IPreOrderQuotaSetting } from "../../types/Inventory/PreOrderQuota";

export const usePreOrderQuotaSetting = () => {
  const { getValues, setValue } = useFormContext<IPreOrderQuotaSetting>();

  const updateQuotaItemList = () => {
    const branch_list = getValues("branch_list");
    const root_item_list = getValues("root_item_list");
    const has_shared_quota = getValues("has_shared_quota");
    const item_quota_list = getValues("item_quota_list");

    const flatItemQuotaList = item_quota_list.flatMap((group, group_index) => {
      return group.map((item, in_group_index) => {
        return {
          ...item,
          group_index,
          in_group_index,
        };
      });
    });

    let quota_sum = 0;

    const formattedItemQuotaList = (
      root_item_list
        ?.sort((a, b) => a.item_unique_id.localeCompare(b.item_unique_id))
        .map((item, group_index) => {
          return (
            branch_list
              ?.sort((a, b) => a.unique_id.localeCompare(b.unique_id))
              .map((branch, in_group_index) => {
                // find previous item with id, using matching item id and branch, for upserting
                const matchingItemWithId = flatItemQuotaList.find(
                  (quota) =>
                    quota.id &&
                    quota.item_id === item.item_id &&
                    quota.branch_id === branch.id
                );
                const quota = matchingItemWithId?.quota ?? 0;
                if (!has_shared_quota) {
                  quota_sum += quota;
                }
                return {
                  id: matchingItemWithId?.id,
                  unique_id: uuidv4(),
                  item_id: item.item_id,
                  item_unique_id: item.item_unique_id,
                  item_name: item.item_name,
                  uom_id: item.uom_id,
                  uom_name: item.uom_name,
                  branch_id: branch.id,
                  branch_unique_id: branch.unique_id,
                  branch_name: branch.name,
                  is_shared_quota: [true, "true"].includes(has_shared_quota),
                  quota,
                  ordered_qty: matchingItemWithId?.ordered_qty ?? 0,
                  available_qty: matchingItemWithId?.available_qty ?? 0,
                  group_index,
                  in_group_index,
                };
              }) ?? []
          );
        }) ?? []
    ).filter((groupedItem) => groupedItem.length);

    setValue("item_quota_list", formattedItemQuotaList);
    if (!has_shared_quota) {
      setValue("shared_quota", quota_sum);
    }
  };

  const recalculateSharedQuota = () => {
    const item_quota_list = getValues("item_quota_list");
    const has_shared_quota = getValues("has_shared_quota");

    if (has_shared_quota) return;

    const quota_sum = item_quota_list
      .flatMap((group) => {
        return group.map((item) => item.quota);
      })
      .reduce((acc, quota) => acc + quota, 0);
    setValue("shared_quota", quota_sum);
  };

  return { updateQuotaItemList, recalculateSharedQuota };
};
