import { Typography, Box, Stack, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IBreadcrumbsAndMenu,
  IExporter,
  IMenuOption,
} from "../../../types/global";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import ContactTable from "../../../components/Table/Contact";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  ContactReportType,
  GenerateContactReportQuery,
  ReportGenerateInput,
  useGenerateContactReportQuery,
} from "../../../generated/contact";
import { useEffect, useMemo, useState } from "react";
import { useModal } from "../../../hooks/use-modal";
import { useSnackbar } from "notistack";
import ExporterModal from "../../../components/UI/Modal/ExporterModal";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import { vendorTypeOptions } from "../../../utils/Formatter/Contact";
import { useStateContext } from "../../../contexts/auth-context";

const Vendor = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("contact.index"),
      to: "/contact",
    },
    {
      name: t("contact.contact_type.vendor"),
    },
  ];

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const graphqlClient = createGraphQLClientWithMiddleware("crm");

  const [exportInput, setExportInput] = useState<ReportGenerateInput | null>(
    null
  );

  const {
    state: { permissions },
  } = useStateContext();

  const vendorOptions = useMemo(() => {
    const options = [];
    if (permissions?.vendor?.create) {
      options.push({
        value: t("button.import"),
      });
    }
    if (permissions?.contact_export?.view) {
      options.push({
        value: t("button.export"),
      });
    }
    return options;
  }, [permissions?.vendor?.create, permissions?.contact_export?.view, t]);

  const { refetch: refetchData, isLoading: isExporting } =
    useGenerateContactReportQuery<GenerateContactReportQuery>(
      graphqlClient,
      {
        input: exportInput,
      },
      {
        enabled: false,
      }
    );

  const exportHandler = async (data: IExporter) => {
    if (data) {
      const { type, start_date, end_date } = data;
      setExportInput({
        report_type: type as ContactReportType,
        start_date: start_date,
        end_date: end_date,
      });
    }
  };

  useEffect(() => {
    const fetchDataAndOpenWindow = async () => {
      try {
        const { data: exportData } = await refetchData();
        window.open(exportData?.GenerateReportNoQueue || "", "_blank");
        enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
          variant: "success",
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    };

    if (exportInput !== null) {
      fetchDataAndOpenWindow();
    }
  }, [enqueueSnackbar, exportInput, refetchData, t]);

  if (exportInput && isExporting) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
        <Typography variant="h5">{t("contact.contact_type.vendor")}</Typography>
        <Stack direction={"row"} gap={1}>
          {permissions?.vendor?.create && (
            <CustomizedButton
              title={`${t("button.add")}${t("contact.contact_type.vendor")}`}
              variant="contained"
              onClick={() => navigate(`${pathname}/add?tab=address`)}
              addIcon
            />
          )}
          {(permissions?.vendor?.create ||
            permissions?.contact_export?.view) && (
            <CustomizedMenuOptions
              isIcon
              options={vendorOptions}
              onSelect={(e) => {
                const value = e.target as HTMLElement;
                switch (value.innerText) {
                  case `${t("button.import")}`:
                    navigate(`${pathname}/import`);
                    break;
                  case `${t("button.export")}`:
                    openModalHandler();
                    break;
                  default:
                }
              }}
            />
          )}
        </Stack>
      </Box>
      <ContactTable type="vendor" />
      <ExporterModal
        open={modal}
        closeModalHandler={closeModalHandler}
        typeOptions={vendorTypeOptions}
        exportHandler={exportHandler}
      />
    </>
  );
};

export default Vendor;
