import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

import { Grid, Typography } from "@mui/material";

import { CustomizedBox } from "../../Custom/CustomizedBox";
import ControlledSelect from "../../Controller/ControlledSelect";
import { ISelectOption } from "../../../types/global";
import { useSalesOption } from "../../../hooks/Sales/use-sales-option";
import { PriceVatType } from "../../../generated/sales";
import { IPriceList } from "../../../types/Inventory/PriceList";
import { ISalesItemList } from "../../../types/Sales";
import { priceListEnumerator } from "../../../utils/Formatter/Sales";
import { useDisable } from "../../../hooks/use-disable";
import { useConfirmation } from "../../../hooks/use-confirmation";
import Confirmation from "../../UI/Confirmation";
import LabelInput from "../../UI/LabelInput";
import { priceVatTypeFormatter } from "../../../utils/Formatter/Global";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { PROMOTION_SETTINGS_VALIDATE_COUPONS } from "../../../services/Marketing/Promotion/PromotionSettings";

const priceVatTypeOptions: ISelectOption[] = [
  {
    label: "ราคารวมภาษี",
    value: PriceVatType.IncludeVat,
  },
  {
    label: "ราคาแยกภาษี",
    value: PriceVatType.ExcludeVat,
  },
];

const currencyOptions: ISelectOption[] = [
  {
    label: "THB",
    value: "THB",
  },
];

type Props = {
  forceDisabled?: boolean;
  documentType?: string;
};

const SalesInfo = ({ forceDisabled, documentType }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled] = useDisable();
  const { id } = useParams();
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const [branchIdSnapshot, setBranchIdSnapshot] = useState<number | undefined>(
    undefined
  );

  const graphQLClientWithHeader: GraphQLClient =
    createGraphQLClientWithMiddleware("marketing");

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const use_preorder_quota = useWatch({
    control,
    name: "use_preorder_quota",
  });

  const {
    saleTypesOption,
    saleChannelsOption,
    branchesOptions,
    itemPriceListOption,
  } = useSalesOption(
    documentType === "sales_order" ? "sales_order" : undefined,
    use_preorder_quota
  );

  const branchIdChangeConfirmHandler = () => {
    setValue("branch_id", branchIdSnapshot);
    setValue("item_list", []);
  };

  const {
    confirmation: branchChangeConfirmation,
    openConfirmationHandler: openBranchChangeConfirmation,
    closeConfirmationHandler: closeBranchChangeConfirmation,
    submitConfirmationHandler: submitBranchChangeConfirmation,
  } = useConfirmation(branchIdChangeConfirmHandler);

  const is_hq = sessionStorage.getItem("is_hq");
  const isReferenceDocumentList = getValues("reference_document_list");

  const salesChannelChangeHandler = async (e: any) => {
    const value = e.target.value;
    if (documentType === "cash_sales") {
      const formValues = getValues([
        "promotion_list",
        "item_list",
        "customer_details",
        "branch_id",
      ]) as any;
      const promotion_list = formValues?.[0];
      if (promotion_list?.length > 0) {
        try {
          await graphQLClientWithHeader.request(
            PROMOTION_SETTINGS_VALIDATE_COUPONS,
            {
              validateInput: {
                coupon_code_list: promotion_list.map(
                  (promotion: any) => promotion.coupon_code
                ),
                customer_id: formValues?.[2]?.id ?? 0,
                total_price: Math.round(
                  formValues?.[1].reduce((acc: number, item: any) => {
                    const itemPrice = item.price_per_unit ?? 0;
                    const discount = item.discount ?? 0;
                    const qty = item.qty ?? 0;

                    const adjustedPrice = Math.max(itemPrice - discount, 0);
                    return acc + adjustedPrice * qty;
                  }, 0)
                ),
                branch_id: formValues?.[3] ?? -1,
                item_list: formValues?.[1]
                  ?.map((item: any) => ({
                    item_id: item.item_id,
                    amount: item.qty,
                  }))
                  .filter((item: any) => !isNaN(item.item_id)),
                sales_channel_id: value,
              },
            }
          );
        } catch (err) {
          enqueueSnackbar(
            `${t("marketing.promotion.sentence.apply_validate_error")} ${t(
              "sales.sales_description"
            )}`,
            { variant: "error" }
          );
          return;
        }
      }
    }
    if (value) {
      const salesChannel = saleChannelsOption.find(
        (channel) => channel.value === value
      );
      if (salesChannel) {
        setValue("sales_channel", salesChannel);
        const foundActivePriceList = salesChannel?.item_price_list?.find(
          (price: IPriceList) => price.is_active
        );
        if (foundActivePriceList) {
          setValue("item_price_list", foundActivePriceList);
          setValue("item_price_list_id", foundActivePriceList.id);
          const currentItemList: ISalesItemList[] = getValues("item_list");
          priceListEnumerator(foundActivePriceList, currentItemList, setValue);
        } else {
          setValue("item_price_list", undefined);
          setValue("item_price_list_id", 0);
        }
      } else {
        setValue("sales_channel", undefined);
      }
    }
    setValue("sales_channel_id", value);
  };

  const branchIdChangeHandler = async (e: any) => {
    const value = e.target.value;
    if (documentType === "cash_sales") {
      const formValues = getValues([
        "promotion_list",
        "item_list",
        "customer_details",
        "sales_channel_id",
      ]) as any;
      const promotion_list = formValues?.[0];
      if (promotion_list?.length > 0) {
        try {
          await graphQLClientWithHeader.request(
            PROMOTION_SETTINGS_VALIDATE_COUPONS,
            {
              validateInput: {
                coupon_code_list: promotion_list.map(
                  (promotion: any) => promotion.coupon_code
                ),
                customer_id: formValues?.[2]?.id ?? 0,
                total_price: Math.round(
                  formValues?.[1].reduce((acc: number, item: any) => {
                    const itemPrice = item.price_per_unit ?? 0;
                    const discount = item.discount ?? 0;
                    const qty = item.qty ?? 0;

                    const adjustedPrice = Math.max(itemPrice - discount, 0);
                    return acc + adjustedPrice * qty;
                  }, 0)
                ),
                branch_id: value,
                item_list: formValues?.[1]
                  ?.map((item: any) => ({
                    item_id: item.item_id,
                    amount: item.qty,
                  }))
                  .filter((item: any) => !isNaN(item.item_id)),
                sales_channel_id: formValues?.[3],
              },
            }
          );
        } catch (err) {
          enqueueSnackbar(
            `${t("marketing.promotion.sentence.apply_validate_error")} ${t(
              "sales.sales_description"
            )}`,
            { variant: "error" }
          );
          return;
        }
      }
    }
    if (documentType === "sales_order" && use_preorder_quota) {
      setBranchIdSnapshot(value);
      openBranchChangeConfirmation();
      return;
    }
    setValue("branch_id", value);
  };

  const salesTypeChangeHandler = async (e: any) => {
    const value = e.target.value;
    if (documentType === "cash_sales") {
      const formValues = getValues([
        "promotion_list",
        "item_list",
        "customer_details",
        "sales_channel_id",
        "branch_id",
      ]) as any;
      const promotion_list = formValues?.[0];
      if (promotion_list?.length > 0) {
        try {
          await graphQLClientWithHeader.request(
            PROMOTION_SETTINGS_VALIDATE_COUPONS,
            {
              validateInput: {
                coupon_code_list: promotion_list.map(
                  (promotion: any) => promotion.coupon_code
                ),
                customer_id: formValues?.[2]?.id ?? 0,
                total_price: Math.round(
                  formValues?.[1].reduce((acc: number, item: any) => {
                    const itemPrice = item.price_per_unit ?? 0;
                    const discount = item.discount ?? 0;
                    const qty = item.qty ?? 0;

                    const adjustedPrice = Math.max(itemPrice - discount, 0);
                    return acc + adjustedPrice * qty;
                  }, 0)
                ),
                branch_id: formValues?.[4] ?? -1,
                item_list: formValues?.[1]
                  ?.map((item: any) => ({
                    item_id: item.item_id,
                    amount: item.qty,
                  }))
                  .filter((item: any) => !isNaN(item.item_id)),
                sales_channel_id: formValues?.[3],
              },
            }
          );
        } catch (err) {
          enqueueSnackbar(
            `${t("marketing.promotion.sentence.apply_validate_error")} ${t(
              "sales.sales_description"
            )}`,
            { variant: "error" }
          );
          return;
        }
      }
    }
    setValue("sales_type", value);
  };

  const referenceDocumentDisabled =
    ["cash_sales", "customer_deposit"].includes(documentType ?? "") &&
    isReferenceDocumentList &&
    isReferenceDocumentList.length > 0;
  const itemPriceListChangeHandler = (e: any) => {
    const value = e.target.value;
    if (value) {
      const itemPriceList: IPriceList = itemPriceListOption.find(
        (price) => price.value === value
      )?.actual_value;

      if (itemPriceList) {
        setValue("item_price_list", itemPriceList);
        const currentItemList: ISalesItemList[] = getValues("item_list");
        priceListEnumerator(itemPriceList, currentItemList, setValue);
      } else {
        setValue("item_price_list", undefined);
      }
    }
  };

  const priceDisabled =
    Boolean(id) &&
    (disabled || (status !== "draft" && status !== "not_approved"));

  return (
    <Fragment>
      <CustomizedBox margin={"1.5rem 0rem"}>
        <Typography fontWeight={600} mb={2} color={"primary.main"}>
          {t("sales.sales_description")}
        </Typography>
        <Grid container spacing={1} mt={1}>
          {documentType !== "customer_deposit" && (
            <>
              <Grid item sm={12} md={4}>
                {disabled ? (
                  <LabelInput
                    label={t("sales.sales_type")}
                    value={getValues("sales_type")}
                  />
                ) : (
                  <ControlledSelect
                    control={control}
                    name="sales_type"
                    label={t("sales.sales_type")}
                    options={saleTypesOption}
                    error={Boolean(errors.sales_type)}
                    helperText={errors?.sales_type?.message?.toString()}
                    onChange={salesTypeChangeHandler}
                    disabled={forceDisabled}
                    disableDefaultOnChange
                  />
                )}
              </Grid>
              <Grid item sm={12} md={4}>
                {disabled ? (
                  <LabelInput
                    label={t("sales.sales_channel")}
                    value={getValues("sales_channel")?.name_th || ""}
                  />
                ) : (
                  <ControlledSelect
                    control={control}
                    name="sales_channel_id"
                    label={t("sales.sales_channel")}
                    options={saleChannelsOption}
                    onChange={salesChannelChangeHandler}
                    error={Boolean(errors.sales_channel_id)}
                    helperText={errors?.sales_channel_id?.message?.toString()}
                    disabled={
                      forceDisabled ||
                      priceDisabled ||
                      referenceDocumentDisabled
                    }
                    disableDefaultOnChange
                  />
                )}
              </Grid>
            </>
          )}
          <Grid item sm={12} md={4}>
            {disabled ? (
              <LabelInput
                label={t("branch.index")}
                value={getValues("branch")?.name || ""}
              />
            ) : (
              <ControlledSelect
                control={control}
                name="branch_id"
                label={t("branch.index")}
                options={branchesOptions}
                onChange={branchIdChangeHandler}
                disabled={
                  is_hq !== "true" ||
                  (status &&
                    status !== "draft" &&
                    status !== "wait_approve" &&
                    status !== "not_approved") ||
                  forceDisabled ||
                  referenceDocumentDisabled
                }
                error={Boolean(errors.branch_id)}
                helperText={errors?.branch_id?.message?.toString()}
                required
                disableDefaultOnChange
              />
            )}
          </Grid>
          <Grid item sm={12} md={4}>
            {disabled ? (
              <LabelInput
                label={t("sales.price_vat_type")}
                value={priceVatTypeFormatter(getValues("price_vat_type"))}
              />
            ) : (
              <ControlledSelect
                control={control}
                name="price_vat_type"
                label={t("sales.price_vat_type")}
                options={priceVatTypeOptions}
                required
                error={Boolean(errors.price_vat_type)}
                helperText={errors?.price_vat_type?.message?.toString()}
                disabled={
                  forceDisabled || priceDisabled || referenceDocumentDisabled
                }
              />
            )}
          </Grid>
          <Grid item sm={12} md={4}>
            {disabled ? (
              <LabelInput
                label={t("global.currency")}
                value={getValues("currency")}
              />
            ) : (
              <ControlledSelect
                control={control}
                name="currency"
                label={t("global.currency")}
                options={currencyOptions}
                disabled
                required
                error={Boolean(errors.currency)}
                helperText={errors?.currency?.message?.toString()}
              />
            )}
          </Grid>
          {documentType !== "customer_deposit" && (
            <Grid item sm={12} md={4}>
              {disabled ? (
                <LabelInput
                  label={t("sales.price_list.index")}
                  value={getValues("item_price_list")?.name || ""}
                />
              ) : (
                <ControlledSelect
                  control={control}
                  name="item_price_list_id"
                  label={t("sales.price_list.index")}
                  options={itemPriceListOption}
                  onChange={itemPriceListChangeHandler}
                  required
                  error={Boolean(errors.item_price_list_id)}
                  helperText={errors?.item_price_list_id?.message?.toString()}
                  disabled={
                    forceDisabled || priceDisabled || referenceDocumentDisabled
                  }
                />
              )}
            </Grid>
          )}
        </Grid>
      </CustomizedBox>
      <Confirmation
        title="การเปลี่ยนตัวเลือก"
        message="หากเปลี่ยนตัวเลือก ข้อมูลที่ระบุด้านล่างจะถูกล้างค่า"
        open={branchChangeConfirmation}
        handleClose={closeBranchChangeConfirmation}
        action={submitBranchChangeConfirmation}
      />
    </Fragment>
  );
};

export default SalesInfo;
