import {
  ColDef,
  GridReadyEvent,
  FirstDataRenderedEvent,
  IServerSideGetRowsParams,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { usePreOrderQuotaSettingColumnDefs } from "./columnDefs";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";
import AgGrid from "../../../UI/AgGrid";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { PRE_ORDER_QUOTA_SETTING_VIEW } from "../../../../services/AgGrid/InventoryAggrid";

interface Props {
  statusFilter: string | null;
}

const PreOrderQuotaSettingTable = ({ statusFilter }: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const navigate = useNavigate();

  const columnDefs: ColDef[] | undefined = usePreOrderQuotaSettingColumnDefs();

  const graphQLClientWithHeaderWms: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        start_date,
        end_date,
        created_date,
        created_by_object,
        last_updated_date,
        is_active,
        ...otherFilterModel
      } = filterModel;
      const formatFilter = {
        ...otherFilterModel,
        start_date: dateFilterModel(start_date),
        end_date: dateFilterModel(end_date),
        created_date: dateFilterModel(created_date),
        last_updated_date: dateFilterModel(last_updated_date),
        created_by: created_by_object,
        is_active: is_active && {
          ...is_active,
          values: is_active.values.map((v: string) => (v === "active" ? 1 : 0)),
        },
      };
      try {
        const { PreOrderQuotaSettingViewsAggrid } =
          await graphQLClientWithHeaderWms.request(
            PRE_ORDER_QUOTA_SETTING_VIEW,
            {
              aggridInput: {
                startRow,
                endRow,
                filterModel: formatFilter,
                sortModel,
              },
            }
          );
        params.success({
          rowData: PreOrderQuotaSettingViewsAggrid.results as any[],
          rowCount: PreOrderQuotaSettingViewsAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const instance = params.api.getFilterInstance("is_active");
      switch (statusFilter) {
        case "active":
          instance?.setModel({ values: ["active"] });
          break;
        case "inactive":
          instance?.setModel({ values: ["inactive"] });
          break;
        default:
          instance?.setModel({});
          break;
      }
      params.api.onFilterChanged();
    },
    [statusFilter]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
    onFilterChanged(params);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(`/inventory/preorder-quota/${encodeURIComponent(params.data.id)}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      onRowDoubleClicked={onRowDoubleClicked}
      onFirstDataRendered={onFirstDataRendered}
      onGridReady={onGridReady}
    />
  );
};

export default PreOrderQuotaSettingTable;
