import { useCallback, useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Alert, Box, Grid, IconButton, Typography } from "@mui/material";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

import CustomizedStatus from "../../../../Custom/CustomizedStatus";
import ControlledTextField from "../../../../Controller/ControlledTextField";
import ControlledDatePicker from "../../../../Controller/ControlledDatePicker";
import CustomizedMenuOptions from "../../../../Custom/CustomizedMenuOptions";
import DeleteConfirmation from "../../../../UI/Modal/DeleteConfirmation";
import { useDisable } from "../../../../../hooks/use-disable";
import { CustomizedTooltip } from "../../../../Custom/CustomizedTooltip";
import { useConfirmation } from "../../../../../hooks/use-confirmation";
import { createGraphQLClientWithMiddleware } from "../../../../../services/graphqlClient";
import {
  WmsModelType,
  WmsUniqueIdGenerateQuery,
  useWmsUniqueIdGenerateQuery,
} from "../../../../../generated/inventory";
import { IPreOrderQuotaSetting } from "../../../../../types/Inventory/PreOrderQuota";
import PreOrderQuotaHeaderStepper from "./HeaderStepper";

type Props = {
  editClickHandler: () => void;
  deleteHandler: (data: any) => Promise<void>;
};

const PreOrderQuotaSettingHeader = ({
  editClickHandler,
  deleteHandler,
}: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [disabled] = useDisable();
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<IPreOrderQuotaSetting>();

  const currentTab = useWatch({
    control,
    name: "tab",
  });

  const name = useWatch({
    control,
    name: "name",
  });

  const isExpired = useWatch({
    control,
    name: "is_expired",
  });

  const graphQLClient = createGraphQLClientWithMiddleware("wms");

  const { refetch: refetchUniqueId } =
    useWmsUniqueIdGenerateQuery<WmsUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: WmsModelType.PreorderQuotaSetting,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const selectModifyOptions = useMemo(() => {
    const options = [
      { value: "แก้ไข", disabled: !disabled },
      { value: "ลบ", disabled: false },
    ];
    return options;
  }, [disabled]);

  const {
    confirmation: deleteConfirmation,
    openConfirmationHandler: openDeleteConfirmation,
    closeConfirmationHandler: closeDeleteConfirmation,
    submitConfirmationHandler: submitDeleteConfirmation,
  } = useConfirmation(deleteHandler);

  return (
    <Box maxWidth={1040}>
      <Grid container spacing={1.5} mt={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5" sx={{ color: "#333333" }}>
              {t("inventory.preorder_quota.setting")}
            </Typography>
            {isExpired && <CustomizedStatus status="inactive_alt" />}
          </Box>
        </Grid>
        {id && (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={1.5} justifyContent="flex-end">
              <Grid item xs={5.5} sm={5.5} md={4} lg={4} xl={4}>
                {
                  <CustomizedMenuOptions
                    fullWidth
                    size="medium"
                    label={"ตัวเลือก"}
                    options={selectModifyOptions}
                    onSelect={(e) => {
                      const value = e.target as HTMLElement;
                      switch (value.innerText) {
                        case "แก้ไข":
                          editClickHandler();
                          break;
                        case "ลบ":
                          openDeleteConfirmation();
                          break;
                        default:
                          break;
                      }
                    }}
                    disabled={!id}
                  />
                }
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {!id && <PreOrderQuotaHeaderStepper />}
      {(id || currentTab === "rootItem") && (
        <Grid container spacing={1.5} alignItems="center">
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={1.5} alignItems="center">
              <Grid item xs={12} sm={12} md={4}>
                <Typography fontWeight={600}>
                  {t("inventory.preorder_quota.unique_id")}
                </Typography>
              </Grid>
              <Grid item xs={11} sm={11} md={7}>
                <ControlledTextField
                  name="unique_id"
                  control={control}
                  placeholder={t("inventory.preorder_quota.unique_id")}
                  error={Boolean(errors?.unique_id)}
                  helperText={
                    errors?.unique_id && errors?.unique_id.message?.toString()
                  }
                  disabled={!!id}
                  viewMode={disabled}
                  required
                />
              </Grid>
              <Grid item xs={1}>
                {!Boolean(id) && (
                  <CustomizedTooltip
                    title={`เรียก${t(
                      "inventory.preorder_quota.unique_id"
                    )}ใหม่`}
                    enterNextDelay={200}
                  >
                    <IconButton
                      onClick={generateUniqueId}
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                      }}
                    >
                      <RestartAltOutlinedIcon />
                    </IconButton>
                  </CustomizedTooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={1.5} justifyContent="flex-end">
              <Grid item xs={12} sm={12} md={4}>
                <ControlledDatePicker
                  name="start_date"
                  control={control}
                  label={t("inventory.preorder_quota.start_date_short")}
                  disabled={disabled}
                  viewMode={disabled}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <ControlledDatePicker
                  name="end_date"
                  control={control}
                  label={t("inventory.preorder_quota.end_date")}
                  disabled={disabled}
                  viewMode={disabled}
                  // minDate={prevEndDate}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <DeleteConfirmation
        title={`ลบ${t("inventory.preorder_quota.index")}`}
        message={
          <Box>
            <Typography>{`ยืนยันการลบ${t(
              "inventory.preorder_quota.index"
            )}`}</Typography>
            <Typography fontWeight="bold">{name}</Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 1,
              }}
            >
              <Alert
                severity="warning"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  maxWidth: "fit-content",
                  padding: "0.125rem 0.5rem",
                  borderRadius: "4px",
                  backgroundColor: (theme) => theme.palette.warning.light,
                }}
              >
                <Typography>
                  {t("inventory.preorder_quota.sentence.delete_confirmation")}
                </Typography>
              </Alert>
            </Box>
          </Box>
        }
        open={deleteConfirmation}
        handleClose={closeDeleteConfirmation}
        action={submitDeleteConfirmation}
        titleColor={"#333333"}
        noDivider
      />
    </Box>
  );
};

export default PreOrderQuotaSettingHeader;
