import { useEffect, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { IPreOrderQuotaSetting } from "../../../../../types/Inventory/PreOrderQuota";
import { useDisable } from "../../../../../hooks/use-disable";
import { useModal } from "../../../../../hooks/use-modal";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ItemNameCell from "./ItemNameCell";
import CloseIcon from "@mui/icons-material/Close";
import CustomizedButton from "../../../../Custom/CustomizedButton";
import CheckedItemModal from "../../../../UI/Modal/CheckedItemModal";
import ControlledSelect from "../../../../Controller/ControlledSelect";
import {
  usePreOrderQuotaRootItemListHeader,
  textAlignToJustify,
} from "../../../../../hooks/Inventory/use-preorder-quota-list-header";
import { usePreOrderQuotaSetting } from "../../../../../hooks/Inventory/use-preorder-quota-setting";

const PreOrderQuotaRootItemList = () => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IPreOrderQuotaSetting>();
  const { id } = useParams();
  const [disabled] = useDisable();
  const {
    modal: itemModal,
    openModalHandler: openItemModalHandler,
    closeModalHandler: closeItemModalHandler,
  } = useModal();
  const { updateQuotaItemList } = usePreOrderQuotaSetting();

  const [itemIds, setItemIds] = useState<string[]>([]);
  const [itemIdsSnapshot, setItemIdsSnapshot] = useState<string[]>([]);

  const { fields, remove, replace } = useFieldArray({
    control,
    name: "root_item_list",
    keyName: "unique_id",
  });

  const watchFieldArray = useWatch({
    control: control,
    name: "root_item_list",
  });

  const lockRows = useWatch({
    control,
    name: "locked_root_item_unique_id_list",
  });

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  useEffect(() => {
    if (fields && fields.length > 0) {
      const uniqueIdList = fields.map(({ item_unique_id }) => item_unique_id);
      setItemIds(uniqueIdList);
      setItemIdsSnapshot(uniqueIdList);
    } else {
      setItemIds([]);
      setItemIdsSnapshot([]);
    }
  }, [fields]);

  const headers = usePreOrderQuotaRootItemListHeader(Boolean(id) || disabled);

  const addItemsHandler = (itemList: any[]) => {
    // filter out Items not selected in current modal session
    let filteredItems = fields.filter((item) =>
      itemIds.includes(item.item_unique_id)
    );

    // get current Items id to prevent duplication when appending newly selected Items
    const filteredItemsId = filteredItems.map((item) => item.item_unique_id);

    itemList.forEach((item) => {
      if (!filteredItemsId.includes(item.sku_name)) {
        filteredItems.push({
          unique_id: uuidv4(),
          item_id: item.item_id,
          item_unique_id: item.sku_name,
          item_sku_name: item.sku_name,
          item_name: item.name,
          unit: item.stock_uom_name_th,
          uom_id: item.stock_uom_id,
          uom_name: item.stock_uom_name_th,
          uom_group: item.uom_group,
        });
      }
    });

    // sort for proerly render order (and for role assignment when submitting)
    filteredItems = filteredItems.sort((a, b) => {
      return (
        itemIds.indexOf(a.item_unique_id) - itemIds.indexOf(b.item_unique_id)
      );
    });

    replace(filteredItems);

    updateQuotaItemList();
    closeItemModalHandler();
  };

  const removeItemhandler = (index: number) => {
    remove(index);
    updateQuotaItemList();
  };

  return (
    <>
      {controlledFields.length > 0 && (
        <TableContainer>
          <Table sx={{ minWidth: 650, overflow: "scroll" }}>
            <TableHead sx={{ bgcolor: "primary.light" }}>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    align={header.align}
                    key={index}
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={header.width}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        width: header.width,
                        justifyContent: textAlignToJustify(header.align),
                      }}
                    >
                      <Typography fontSize={14} fontWeight={600}>
                        {header.thaiLabel}
                      </Typography>
                      {header.required && (
                        <Typography
                          fontSize={14}
                          color={"error.main"}
                          fontWeight={600}
                        >
                          *
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {controlledFields.map((field, index) => (
                <TableRow key={field.unique_id} sx={{ verticalAlign: "top" }}>
                  <TableCell align={headers[0]?.align}>
                    <Box width={headers[0]?.width} py={1}>
                      <Typography>{index + 1}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell align={headers[1]?.align}>
                    <Box width={headers[1]?.width}>
                      <ItemNameCell field={field} index={index} />
                    </Box>
                  </TableCell>
                  <TableCell align={headers[2]?.align}>
                    <Box width={headers[2]?.width}>
                      {!disabled ? (
                        <ControlledSelect
                          control={control}
                          name={`root_item_list.${index}.unit`}
                          error={Boolean(
                            errors?.root_item_list &&
                              errors?.root_item_list?.[index] &&
                              errors?.root_item_list?.[index]?.unit
                          )}
                          options={[
                            {
                              label: field.unit,
                              value: field.unit,
                            },
                          ]}
                          onChange={(e: any) => {
                            setValue(
                              `root_item_list.${index}.unit`,
                              e.target?.value
                            );
                            updateQuotaItemList();
                          }}
                          disableDefaultOnChange
                        />
                      ) : (
                        <Typography>{field.unit}</Typography>
                      )}
                    </Box>
                  </TableCell>
                  {!id && (
                    <TableCell align={headers[3]?.align}>
                      <Box width={headers[3]?.width} py={0.5}>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          sx={{ ml: 1, color: "#BDBDBD" }}
                          onClick={() => removeItemhandler(index)}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!disabled && (
        <Box mt={1}>
          <CustomizedButton
            title="เพิ่มสินค้า"
            variant="outlined"
            onClick={openItemModalHandler}
            addIcon
          />
        </Box>
      )}
      <CheckedItemModal
        itemIds={itemIds}
        setItemIds={setItemIds}
        itemIdsSnapshot={itemIdsSnapshot}
        setItemIdsSnapshot={setItemIdsSnapshot}
        closeItemTable={closeItemModalHandler}
        rowSelection="multiple"
        showSelectItem={itemModal}
        finishItemsSelect={addItemsHandler}
        type="preorder_quota"
        useAltButtons
        titleColor={"#333333"}
        lockRows={id ? lockRows : undefined}
      />
    </>
  );
};

export default PreOrderQuotaRootItemList;
