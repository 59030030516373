import { FieldArrayWithId, useFormContext } from "react-hook-form";
import LaunchIcon from "@mui/icons-material/Launch";
import { IPreOrderQuotaSetting } from "../../../../../types/Inventory/PreOrderQuota";
import { Box, IconButton, Typography } from "@mui/material";
import { useDisable } from "../../../../../hooks/use-disable";
import { CustomizedTooltip } from "../../../../Custom/CustomizedTooltip";
import ControlledTextField from "../../../../Controller/ControlledTextField";
import { usePreOrderQuotaSetting } from "../../../../../hooks/Inventory/use-preorder-quota-setting";

type Props = {
  field: FieldArrayWithId<IPreOrderQuotaSetting, "root_item_list", "unique_id">;
  index: number;
};

const ItemNameCell = ({ field, index }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IPreOrderQuotaSetting>();
  const [disabled] = useDisable();

  const { updateQuotaItemList } = usePreOrderQuotaSetting();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "stretch",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          textAlign: "left",
        }}
      >
        {disabled ? (
          <Typography
            variant="body2"
            align="left"
            color="primary"
            onClick={() =>
              window.open(
                `/inventory/items/${field.item_sku_name}?tab=item&subtab=general`,
                "_blank"
              )
            }
            sx={{ cursor: "pointer" }}
          >
            {field.item_name}
          </Typography>
        ) : (
          <ControlledTextField
            placeholder="ชื่อสินค้า"
            control={control}
            error={Boolean(
              errors?.root_item_list &&
                errors?.root_item_list?.[index] &&
                errors?.root_item_list?.[index]?.item_name
            )}
            name={`root_item_list.${index}.item_name`}
            onBlur={updateQuotaItemList}
          />
        )}
        <Typography
          variant="overline"
          sx={{
            my: 0,
            ml: !disabled ? 2 : 0,
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {field.item_sku_name}
        </Typography>
      </Box>
      {!disabled && (
        <CustomizedTooltip title="กดเพื่อดูรายละเอียดสินค้า">
          <IconButton
            size="small"
            sx={{
              color: "secondary.main",
              alignSelf: "flex-start",
              pt: 1,
            }}
            onClick={() =>
              window.open(
                `/inventory/items/${field.item_sku_name}?tab=item&subtab=general`,
                "_blank"
              )
            }
          >
            <LaunchIcon fontSize="small" />
          </IconButton>
        </CustomizedTooltip>
      )}
    </Box>
  );
};

export default ItemNameCell;
