import { GraphQLClient } from "graphql-request";
import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  GetRowIdParams,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { BRANCHES_AGGRIDS } from "../../../services/AgGrid/BranchAgGrid";
import CheckboxAggridModal from "./CheckboxAggridModal";
import useBranchColumnDefs from "../../Table/Setting/Branch/useBranchColumnDefs";

type CheckedBranchModalProps = {
  showSelectBranch: boolean;
  closeBranchTable: () => void;
  finishBranchSelect: (data: any) => void;
  branchIds: string[];
  setBranchIds: Dispatch<SetStateAction<string[]>>;
  branchIdsSnapshot: string[];
  setBranchIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  rowSelection?: "single" | "multiple";
  lockRows?: (string | undefined)[];
  keyName?: string;
  useAltButtons?: boolean;
  titleColor?: string;
};

const CheckedBranchModal = ({
  showSelectBranch,
  closeBranchTable,
  finishBranchSelect,
  branchIds,
  setBranchIds,
  branchIdsSnapshot,
  setBranchIdsSnapshot,
  rowSelection,
  keyName,
  useAltButtons,
  lockRows,
  titleColor,
}: CheckedBranchModalProps) => {
  const { t } = useTranslation();
  const selectBranchGridRef = useRef();

  const columnDefs = useBranchColumnDefs({});

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { status, address, ...otherFilter } = filterModel;
      const formatFilter = {
        ...otherFilter,
        status: status && {
          ...status,
          values: status.values.map((v: string) => parseInt(v)),
        },
        address: address && {
          ...address,
          filterType: "json",
          type: address.type,
          path: ["address_name"],
        },
      };
      try {
        const { BranchesFindManyAggrid } = await graphQLClient.request(
          BRANCHES_AGGRIDS,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: BranchesFindManyAggrid.results as any[],
          rowCount: BranchesFindManyAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  const getRowId = useCallback(
    (params: GetRowIdParams) => {
      return keyName ? params.data[keyName] : params.data.unique_id;
    },
    [keyName]
  );

  return (
    <CheckboxAggridModal
      modalTitle={"สาขา"}
      btnTitle={useAltButtons ? t("button.confirm") : t("button.choose")}
      gridRef={selectBranchGridRef}
      height={665}
      columnDefs={columnDefs}
      rowSelection={rowSelection ?? "multiple"}
      onFinishEditing={finishBranchSelect}
      modalIsOpen={showSelectBranch}
      getRowId={getRowId}
      closeModal={closeBranchTable}
      selectedIds={branchIds}
      setSelectedIds={setBranchIds}
      idsSnapshot={branchIdsSnapshot}
      setIdsSnapshot={setBranchIdsSnapshot}
      onGridReady={onGridReady}
      keyName={keyName}
      useAltButtons={useAltButtons}
      altTitleColor={titleColor}
      lockRows={lockRows}
    />
  );
};

export default CheckedBranchModal;
