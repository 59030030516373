import { useFormContext } from "react-hook-form";
import { CustomizedBox } from "../../../../Custom/CustomizedBox";
import { Box, Typography, FormHelperText } from "@mui/material";
import { useTranslation } from "react-i18next";
import PreOrderQuotaRootItemList from "../../../../Table/Inventory/PreOrderQuota/RootItemList/PreOrderQuotaRootItemList";
import { IPreOrderQuotaSetting } from "../../../../../types/Inventory/PreOrderQuota";

const PreOrderQuotaSettingRootItem = () => {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext<IPreOrderQuotaSetting>();

  return (
    <CustomizedBox>
      <Box display={"flex"} mb={1} gap={0.3}>
        <Typography
          fontWeight={600}
          color={Boolean(errors.root_item_list) ? "error.main" : undefined}
        >
          {t("inventory.items.index")}
        </Typography>
        <Typography fontWeight={600} color={"error.main"}>
          *
        </Typography>
      </Box>
      <PreOrderQuotaRootItemList />
      {Boolean(errors.root_item_list) && (
        <FormHelperText error={Boolean(errors.root_item_list)}>
          {errors.root_item_list?.message}
        </FormHelperText>
      )}
    </CustomizedBox>
  );
};

export default PreOrderQuotaSettingRootItem;
