import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { IBreadcrumbsAndMenu, IMenuOption, ITab } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { Box, Typography, Stack } from "@mui/material";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import PreOrderQuotaSettingTable from "../../../components/Table/Inventory/PreOrderQuota";

const PreOrderQuotaSetting = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const statusFilter = searchParams.get("filter");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.preorder_quota.index"),
    },
  ];

  const menuOptions: IMenuOption[] = [
    { value: t(`inventory.export`), disabled: true },
  ];

  const tabs: ITab[] = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    {
      label: t("inventory.active"),
      path: `${pathname}?filter=active`,
    },
    {
      label: t("inventory.notActive"),
      path: `${pathname}?filter=inactive`,
    },
  ];

  const currentTab = pathname + (statusFilter ? `?filter=${statusFilter}` : "");

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
        <Typography variant="h5">
          {t("inventory.preorder_quota.index")}
        </Typography>
        <Stack direction={"row"} gap={1}>
          <CustomizedButton
            variant="contained"
            title={t("button.create") + t("inventory.preorder_quota.quota")}
            onClick={() => navigate("/inventory/preorder-quota/add")}
            addIcon
          />
          <CustomizedMenuOptions
            isIcon
            options={menuOptions}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerText) {
                case `${t("button.export")}`:
                  break;
                default:
              }
            }}
          />
        </Stack>
      </Box>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <PreOrderQuotaSettingTable statusFilter={statusFilter} />
    </>
  );
};

export default PreOrderQuotaSetting;
