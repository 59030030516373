import { Fragment } from "react";
import { Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";

import TotalBox from "../../../components/UI/TotalBox";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import CurrentStockByWarehouse from "../../../components/Table/Inventory/Item/CurrentStock/Warehouse";
import CurrentStockByLocation from "../../../components/Table/Inventory/Item/CurrentStock/Location";
import CurrentStockBySerialNumber from "../../../components/Table/Inventory/Item/CurrentStock/SerialNumber";
import CurrentStockByBranch from "../../../components/Table/Inventory/Item/CurrentStock/Branch";

import { ITab } from "../../../types/global";
import { IItem } from "../../../types/Inventory/item";
import { ItemType } from "../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  usePreOrderQuotaAvaialbleForStockCardQuery,
  PreOrderQuotaAvaialbleForStockCardQuery,
} from "../../../generated/inventory";

import { branchInfoFromSessionStorage } from "../../../utils/Global";
import { useItemCurrentStockSku } from "../../../hooks/Inventory/use-item-current-stock";

const CurrentStockTab = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const filter = searchParams.get("filter");

  const { getValues } = useFormContext<IItem>();

  const itemType = getValues("type");
  const itemSkuName = getValues("sku");
  const bundleItemList = getValues("bundle_item_detail_list");
  // const item_unique_id = getValues("unique_id");

  const { is_hq, branch_id } = branchInfoFromSessionStorage();

  // const graphQLClient = createGraphQLClientWithMiddleware("wms");

  // const { data } =
  //   usePreOrderQuotaAvaialbleForStockCardQuery<PreOrderQuotaAvaialbleForStockCardQuery>(
  //     graphQLClient,
  //     {
  //       input: {
  //         item_unique_id,
  //         branch_id: parseInt(branch_id || "-1"),
  //       },
  //     }
  //   );

  const itemUom = getValues("stock_uom.name_th");

  const tabs: ITab[] = [
    {
      label: t("inventory.items.onHandItemWarehouseBranch"),
      path: `${pathname}?tab=currentstock`,
    },
    {
      label: t("inventory.items.onHandItemWarehouseTab"),
      path: `${pathname}?tab=currentstock&filter=warehouse`,
    },
    {
      label: t("inventory.items.onHandItemWarehouseLocation"),
      path: `${pathname}?tab=currentstock&filter=location`,
    },
    {
      label: t("inventory.items.onHandItemWarehouseSN"),
      path: `${pathname}?tab=currentstock&filter=serial_number`,
    },
  ];

  const currentTab = `${pathname}?tab=currentstock${
    filter ? `&filter=${filter}` : ""
  }`;

  const renderfilter = (filter: string | null) => {
    switch (filter) {
      case "warehouse":
        return <CurrentStockByWarehouse />;
      case "location":
        return <CurrentStockByLocation />;
      case "serial_number":
        return <CurrentStockBySerialNumber />;
      default:
        return <CurrentStockByBranch />;
    }
  };

  const { sku, skuByBranch, bundleSkuQty } = useItemCurrentStockSku(
    itemSkuName || "",
    bundleItemList || []
  );

  return (
    <>
      <Typography fontWeight={600} color={"primary.main"} mt={2} fontSize={16}>
        {itemSkuName}
      </Typography>
      <Grid container spacing={1.5} my={2}>
        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <TotalBox
            title={"คงเหลือสุทธิ"}
            margin={"0"}
            unit={itemUom}
            value={
              itemType === ItemType.Bundle
                ? bundleSkuQty.available_qty
                : is_hq === "true"
                ? sku?.available_qty || 0
                : skuByBranch?.available_qty || 0
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <TotalBox
            title={"คงคลัง"}
            margin={"0"}
            unit={itemUom}
            value={
              itemType === ItemType.Bundle
                ? bundleSkuQty.stock_qty
                : is_hq === "true"
                ? sku?.stock_qty || 0
                : skuByBranch?.stock_qty || 0
            }
          />
        </Grid>
        {itemType !== ItemType.Bundle && (
          <Fragment>
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <TotalBox
                title={"จัดซื้อ"}
                margin={"0"}
                unit={itemUom}
                value={
                  is_hq === "true"
                    ? sku?.purchase_ordered_qty || 0
                    : skuByBranch?.purchase_ordered_qty || 0
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <TotalBox
                title={"ถูกจองขาย"}
                margin={"0"}
                unit={itemUom}
                value={
                  is_hq === "true"
                    ? sku?.sale_committed_qty || 0
                    : skuByBranch?.sale_committed_qty || 0
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <TotalBox
                title={"ระหว่างทาง"}
                margin={"0"}
                unit={itemUom}
                value={
                  is_hq === "true"
                    ? sku?.transit_qty || 0
                    : skuByBranch?.transit_qty || 0
                }
              />
            </Grid>
          </Fragment>
        )}
        {/* {data?.PreOrderQuotaAvaialbleForStockCard !== null && (
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <TotalBox
              title={"พรีออเดอร์"}
              margin={"0"}
              unit={itemUom}
              value={data?.PreOrderQuotaAvaialbleForStockCard ?? 0}
            />
          </Grid>
        )} */}
      </Grid>
      <CustomizedTab
        tabs={itemType === ItemType.Bundle ? tabs.slice(0, 3) : tabs}
        currentTab={currentTab}
        subtab
      />
      {renderfilter(filter)}
    </>
  );
};

export default CurrentStockTab;
