import { useEffect, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { IPromotion } from "../../../../../types/Marketing/Promotion";
import { useDisable } from "../../../../../hooks/use-disable";
import { useModal } from "../../../../../hooks/use-modal";
import { usePromotionItemListHeader } from "../../../../../hooks/Marketing/use-promotion-list-header";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ItemNameCell from "./ItemNameCell";
import ControlledNumberTextField from "../../../../Controller/ControlledNumberTextField";
import { numberFormatter } from "../../../../../utils/Formatter/Global";
import CloseIcon from "@mui/icons-material/Close";
import CustomizedButton from "../../../../Custom/CustomizedButton";
import CheckedItemModal from "../../../../UI/Modal/CheckedItemModal";
import { v4 as uuidv4 } from "uuid";
import ControlledSelect from "../../../../Controller/ControlledSelect";

type Props = {
  fieldArrayName: "item_condition" | "redeem_item";
};

const PromotionItemList = ({ fieldArrayName }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IPromotion>();
  const [disabled] = useDisable();

  const watchIsApplied = useWatch({ control, name: "is_applied" });
  const watchPromotionCategory = useWatch({
    control,
    name: "promotion_category",
  });

  const isApplied = Boolean(watchIsApplied);
  const isFixedRedeemItemlist =
    fieldArrayName === "redeem_item" &&
    watchPromotionCategory === "discount_fix_price";

  const {
    modal: itemModal,
    openModalHandler: openItemModalHandler,
    closeModalHandler: closeItemModalHandler,
  } = useModal();

  const [itemIds, setItemIds] = useState<string[]>([]);
  const [itemIdsSnapshot, setItemIdsSnapshot] = useState<string[]>([]);

  const { fields, remove, replace } = useFieldArray({
    control,
    name: fieldArrayName,
    keyName: "unique_id",
  });

  useEffect(() => {
    if (fields && fields.length > 0) {
      const uniqueIdList = fields.map(({ item_sku_name }) => item_sku_name);
      setItemIds(uniqueIdList);
      setItemIdsSnapshot(uniqueIdList);
    } else {
      setItemIds([]);
      setItemIdsSnapshot([]);
    }
  }, [fields]);

  const headers = usePromotionItemListHeader(
    disabled,
    watchPromotionCategory,
    fieldArrayName
  );

  const addItemsHandler = (itemList: any[]) => {
    // filter out Items not selected in current modal session
    let filteredItems = fields.filter((item) =>
      itemIds.includes(item.item_sku_name)
    );

    // get current Items id to prevent duplication when appending newly selected Items
    const filteredItemsId = filteredItems.map((item) => item.item_sku_name);

    itemList.forEach((item) => {
      if (!filteredItemsId.includes(item.sku_name)) {
        filteredItems.push({
          unique_id: uuidv4(),
          item_id: item.item_id,
          item_unique_id: item.unique_id,
          item_sku_name: item.sku_name,
          item_name: item.name,
          qty: 1,
          unit: item.stock_uom_name_th,
          uom_id: item.stock_uom_id,
          uom_name: item.stock_uom_name_th,
          uom_group: item.uom_group,
          fixed_price: isFixedRedeemItemlist ? 0 : undefined,
        });
      }
    });

    // sort for proerly render order (and for role assignment when submitting)
    filteredItems = filteredItems.sort((a, b) => {
      return (
        itemIds.indexOf(a.item_sku_name) - itemIds.indexOf(b.item_sku_name)
      );
    });

    replace(filteredItems);
    closeItemModalHandler();
  };

  const textAlignToJustify = (align?: string) => {
    switch (align) {
      case "left":
        return "flex-start";
      case "right":
        return "flex-end";
      default:
        return "center";
    }
  };

  return (
    <>
      {fields.length > 0 && (
        <TableContainer>
          <Table sx={{ minWidth: 650, overflow: "scroll" }}>
            <TableHead sx={{ bgcolor: "primary.light" }}>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    align={header.align}
                    key={index}
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={header.width}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        width: header.width,
                        justifyContent: textAlignToJustify(header.align),
                      }}
                    >
                      <Typography fontSize={14} fontWeight={600}>
                        {header.thaiLabel}
                      </Typography>
                      {header.required && (
                        <Typography
                          fontSize={14}
                          color={"error.main"}
                          fontWeight={600}
                        >
                          *
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((field, index) => (
                <TableRow key={field.unique_id} sx={{ verticalAlign: "top" }}>
                  <TableCell align={headers[0]?.align}>
                    <Box width={headers[0]?.width} py={1}>
                      <Typography>{index + 1}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell align={headers[1]?.align}>
                    <Box width={headers[1]?.width}>
                      <ItemNameCell
                        field={field}
                        index={index}
                        fieldArrayName={fieldArrayName}
                      />
                    </Box>
                  </TableCell>
                  {isFixedRedeemItemlist && (
                    <TableCell align={headers[2]?.align}>
                      <Box width={headers[2]?.width}>
                        {!disabled ? (
                          <ControlledNumberTextField
                            fullWidth
                            control={control}
                            name={`${fieldArrayName}.${index}.fixed_price`}
                            error={Boolean(
                              errors?.[fieldArrayName] &&
                                errors?.[fieldArrayName]?.[index] &&
                                errors?.[fieldArrayName]?.[index]?.fixed_price
                            )}
                            decimalScale={4}
                            textAlign="right"
                            disabled={isApplied}
                          />
                        ) : (
                          <Typography>
                            {numberFormatter(field.fixed_price, 4)}
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                  )}
                  <TableCell
                    align={
                      isFixedRedeemItemlist
                        ? headers[3]?.align
                        : headers[2]?.align
                    }
                  >
                    <Box
                      width={
                        isFixedRedeemItemlist
                          ? headers[3]?.width
                          : headers[2]?.width
                      }
                    >
                      {!disabled ? (
                        <ControlledNumberTextField
                          fullWidth
                          control={control}
                          name={`${fieldArrayName}.${index}.qty`}
                          error={Boolean(
                            errors?.[fieldArrayName] &&
                              errors?.[fieldArrayName]?.[index] &&
                              errors?.[fieldArrayName]?.[index]?.qty
                          )}
                          textAlign="right"
                          disabled={isApplied}
                        />
                      ) : (
                        <Typography>{numberFormatter(field.qty)}</Typography>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell
                    align={
                      isFixedRedeemItemlist
                        ? headers[4]?.align
                        : headers[3]?.align
                    }
                  >
                    <Box
                      width={
                        isFixedRedeemItemlist
                          ? headers[4]?.width
                          : headers[3]?.width
                      }
                    >
                      {!disabled ? (
                        <ControlledSelect
                          control={control}
                          name={`${fieldArrayName}.${index}.unit`}
                          error={Boolean(
                            errors?.[fieldArrayName] &&
                              errors?.[fieldArrayName]?.[index] &&
                              errors?.[fieldArrayName]?.[index]?.unit
                          )}
                          options={[
                            {
                              label: field.unit,
                              value: field.unit,
                            },
                          ]}
                          disabled
                        />
                      ) : (
                        <Typography>{field.unit}</Typography>
                      )}
                    </Box>
                  </TableCell>
                  {!disabled && !isApplied && (
                    <TableCell
                      align={
                        isFixedRedeemItemlist
                          ? headers[5]?.align
                          : headers[4]?.align
                      }
                    >
                      <Box
                        width={
                          isFixedRedeemItemlist
                            ? headers[5]?.width
                            : headers[4]?.width
                        }
                        py={0.5}
                      >
                        <IconButton
                          aria-label="delete"
                          size="small"
                          sx={{ ml: 1, color: "#BDBDBD" }}
                          onClick={() => remove(index)}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!disabled && !isApplied && (
        <Box mt={1}>
          <CustomizedButton
            title="เพิ่มสินค้า"
            variant="outlined"
            onClick={openItemModalHandler}
            addIcon
          />
        </Box>
      )}
      <CheckedItemModal
        itemIds={itemIds}
        setItemIds={setItemIds}
        itemIdsSnapshot={itemIdsSnapshot}
        setItemIdsSnapshot={setItemIdsSnapshot}
        closeItemTable={closeItemModalHandler}
        rowSelection="multiple"
        showSelectItem={itemModal}
        finishItemsSelect={addItemsHandler}
        type="promotion"
        useAltButtons
        titleColor={"#333333"}
      />
    </>
  );
};

export default PromotionItemList;
