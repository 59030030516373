import { Box, Divider, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { IPreOrderQuotaAvailableItem } from "../../types/Inventory/PreOrderQuota";

interface Props {
  isSufficient: boolean;
  formatData: any[];
  uom: string;
  shouldHavePreOrderQuota?: boolean;
  availablePreOrderQuota?: IPreOrderQuotaAvailableItem;
}

const ItemQty = ({
  isSufficient,
  formatData,
  uom,
  shouldHavePreOrderQuota,
  availablePreOrderQuota,
}: Props) => {
  return (
    <Box
      sx={{ p: 1, display: "flex", flexDirection: "column", width: "250px" }}
    >
      <Box
        sx={{
          mb: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: isSufficient ? "#8FCBB1" : "#F44336",
          fontSize: "13px",
        }}
      >
        <CircleIcon fontSize="inherit" />
        <Typography ml={1}>
          {isSufficient ? "สินค้าเพียงพอ" : "สินค้าไม่เพียงพอ"}
        </Typography>
      </Box>
      <Divider />
      {shouldHavePreOrderQuota && availablePreOrderQuota && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              pt: 1,
              justifyContent: "space-between",
            }}
          >
            <Typography color="primary.main">{"โควตาพรีออเดอร์"}</Typography>
            <Typography
              sx={{ flex: "1", mr: 1, color: "primary.main" }}
              align="right"
            >
              {availablePreOrderQuota?.available_qty?.toLocaleString()}
            </Typography>
            <Typography color="primary.main">{uom}</Typography>
          </Box>
          <Typography
            sx={{
              ml: 1,
              color: "primary.main",
              "&::before": {
                content: "'•'",
                marginRight: 1,
                fontSize: "1.2em",
              },
            }}
          >
            {availablePreOrderQuota?.setting_unique_id}
          </Typography>
        </>
      )}
      {shouldHavePreOrderQuota && !availablePreOrderQuota && (
        <Typography
          sx={{
            mt: 1,
            color: "#E41B1B",
          }}
        >
          {"ไม่มีโควตาพรีออเดอร์"}
        </Typography>
      )}
      {formatData.map((item) => (
        <Box
          key={item.status}
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 1,
            justifyContent: "space-between",
          }}
        >
          <Typography>{item.status}</Typography>
          <Typography sx={{ flex: "1", mr: 1 }} align="right">
            {item.amount && item.amount.toLocaleString()}
          </Typography>
          <Typography>{uom}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ItemQty;
