import { gql } from "graphql-request";

export const GET_PREORDER_QUOTA_FOR_STOCK_CARD = gql`
  query PreOrderQuotaAvaialbleForStockCard(
    $input: PreOrderQuotaForStockCardInput
  ) {
    PreOrderQuotaAvaialbleForStockCard(input: $input)
  }
`;

export const PREORDER_QUOTA_NAME_EXISTS = gql`
  query PreOrderQuotaNameExists($name: String!) {
    PreOrderQuotaNameExists(name: $name)
  }
`;
